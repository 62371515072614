/***********************************************
 * Component: usePageTracking
 * Description: Google Analytics with information such as the time spent on the page and the page URL.

 *
 * Copyright:
 * (c) Abbott 2023
 **********************************************/
import { useEffect } from "react";
import { event } from "../utils/gaStatistics/gaEvent";

const usePageTracking = (url) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      try {
        event("Time Spent", "Page Tracking", url, 1, true);
      } catch (error) {
        console.error("An Error occurred during page tracking:", error);
      }
    }, 60 * 1000);
    return () => clearInterval(intervalId);
  }, [url]);
};

export default usePageTracking;
