import React, { useEffect, useState } from 'react';
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import ".././App.scss";
import Layout from "./Layout";
import PWAInitailizer from "../utils/PWA";
import ReduxProvider from "../providers/reduxProvider";
import ReactQueryProvider from "../providers/reactQueryProvider";
import { initializeMetaPixel } from '../utils/metaPixel';
import { getUserCountry } from '../utils/geolocation';

const App = () => {
  const [countryCode, setCountryCode] = useState(null);

  useEffect(() => {
    const initApp = async () => {
      const userCountry = await getUserCountry();
      setCountryCode(userCountry);
      if (userCountry) {
        initializeMetaPixel(userCountry);
      }
    };

    initApp();
  }, []);
  
  return (
    <ReduxProvider>
      <ReactQueryProvider>
        <CssBaseline />
        <BrowserRouter>
          <PWAInitailizer />
          <Layout countryCode={countryCode} />
        </BrowserRouter>
      </ReactQueryProvider>
    </ReduxProvider>
  );
};

export default App;