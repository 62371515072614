function calculateAngle_shoulder_Hip_Knee(a, b, c) {
  var ba = Math.sqrt(Math.pow(a[0] - b[0], 2) + Math.pow(a[1] - b[1], 2));
  var bc = Math.sqrt(Math.pow(c[0] - b[0], 2) + Math.pow(c[1] - b[1], 2));
  var ac = Math.sqrt(Math.pow(c[0] - a[0], 2) + Math.pow(c[1] - a[1], 2));

  var angle = Math.acos(
    (Math.pow(ba, 2) + Math.pow(bc, 2) - Math.pow(ac, 2)) / (2 * ba * bc)
  );

  angle = angle * (180 / Math.PI);

  return angle;
}
export default calculateAngle_shoulder_Hip_Knee;
