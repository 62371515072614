import { useLocation } from "react-router-dom";
import { Manual_Test } from "../../utils/constants/internalUrls";
import arrow from "../../assets/Vectors/SVG/ic_down_right_Arrow.svg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const FormSectionLabel = ({ label }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { language } = i18next;

  const isVn = language === "vn";
  const isIn = language === "in";

  return (
    <div className="flex flex-row mb-4">
      <img
        alt="Arrow"
        src={arrow}
        className={
          isIn
            ? "w-7 h-7"
            : location.pathname === Manual_Test
            ? "w-7 h-7"
            : "lg:w-auto lg:h-auto w-7"
        }
      />
      <span
        className={`text-xl ml-2 ${
          location.pathname === Manual_Test ? "lg:text-2xl" : "lg:text-3xl"
        } ${language === "thai" || isVn ? "font-medium" : "font-bold"}  ${
          isVn && "uppercase"
        } ${isIn ? "lg:text-xl" : ""}`}
      >
        {t(label)}
      </span>
    </div>
  );
};

export default FormSectionLabel;
