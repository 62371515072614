import { configureStore } from "@reduxjs/toolkit";
import reducer from "./reducer";
import initialState from "./initialState";
import { persistStore } from "redux-persist";

export default function configureReduxStore() {
  const store = configureStore({
    reducer: reducer,
    initialState,
  });

  const persistor = persistStore(store);

  return { store, persistor };
}
