import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import TimerIcon from "@mui/icons-material/Timer";
import arrow from "./../../../assets/Vectors/SVG/ic_down_right_Arrow.svg";
import { event } from "../../../utils/gaStatistics/gaEvent";
import { MUSCLE_AGE_RESULTS } from "../../../utils/constants/internalUrls";
import i18next from "i18next";

const RightPanel = ({ setTime }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [testTime, setTestTime] = useState("");
  const language=i18next.language

  const calculateMuscleAge = () => {
    if (testTime) {
      event("Submission", "Form", "Age Calculation No Camera", 1, false);
      setTime(testTime);
      navigate(MUSCLE_AGE_RESULTS);
    }
  };

  const renderBoxDescription = (title, description) => {
    return (
      <div className={`flex flex-col mb-5`}>
        <div className="flex flex-row mb-4">
          <img
            alt={
              title === "motivating" ? 1 : title === "completeTestRep" ? 2 : 3
            }
            src={arrow}
            className="w-7 h-7 lg:w-9 lg:h-9"
          />
          <span className="text-xl lg:text-3xl ml-2  font-bold">
            {t(`cameraFallback.${title}`)}
          </span>
        </div>
        <div className="flex flex-row text-justify text-[20px]">
          {t(`cameraFallback.${description}`)}
        </div>
      </div>
    );
  };

  return (
    <div className="max-w-2xl flex justify-center items-center lg:h-screen">
      <div className="p-4 bg-white rounded-lg shadow-lg lg:p-12 mt-12 lg:mt-0 w-full">
        <div className="flex flex-col text-[20px]">
          {renderBoxDescription("motivating", "explanatory")}
          {renderBoxDescription("completeTestRep", "stepByStep")}
          {renderBoxDescription("startChallenge", "instructive")}
          <hr className="mb-6" />
          <div className="flex flex-col">
            <div className="flex flex-row mb-3">
              <img alt="Arrow" src={arrow} className="w-7 h-7 lg:w-9 lg:h-9" />
              <span className="text-base lg:text-[20px] ml-2 font-bold">
                {t("cameraFallback.inputTestTime")}
              </span>
            </div>
            <div className="flex flex-row">
              <FormControl
                sx={{ marginBottom: "1em", width: "100%" }}
                variant="outlined"
              >
                <InputLabel
                  className="font-brandon"
                  sx={{ padding: "0 1em" }}
                  htmlFor="outlined-adornment-password"
                >
                  {t("cameraFallback.yourTime")}
                </InputLabel>
                <OutlinedInput
                  className="p-4 border-solid border-gray-300 border-[1px] font-medium rounded-[4px] w-full h-14 placeholder:text-[19px] placeholder:font-brandon placeholder:text-gray-600"
                  id="outlined-adornment-password"
                  endAdornment={
                    <InputAdornment position="start">
                      <TimerIcon
                        aria-label="toggle password visibility"
                        edge="end"
                      />
                    </InputAdornment>
                  }
                  label="Your test time in seconds"
                  value={testTime}
                  type="Number"
                  onChange={(e) => setTestTime(e.target.value)}
                />
              </FormControl>
            </div>
            <button
              onClick={calculateMuscleAge}
              className="w-full rounded-lg bg-abbottPrimaryBlue p-4 text-white z-20 hover:bg-opacity-90"
            >
              {t("cameraFallback.calculateMuscle")}
            </button>
            <div className="flex flex-col mt-6">
              <div className="text-xs lg:text-sm mb-4">
                {t("calculation")}
              </div>
              <div className="text-xs lg:text-sm">
                {t("reference")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightPanel;
