import { connect } from "react-redux";

import RightPanel from "./rightPanel";
import { setTime } from "../../../store/slices/userConfig";

const mapDispatchToProps = (dispatch) => {
  return {
    setTime: (time) => dispatch(setTime(time)),
  };
};

export default connect(null, mapDispatchToProps)(RightPanel);
