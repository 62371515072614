/*! **********************************************************************************************
 * This is the i18next translation file for the German language.
 * The file exports an object containing key-value pairs for the German translations of the application.
 * The translations include text for the home page, the SelectAge component, and the Assist component.
 * This object contains the German translations for the same keys as in the English translation file.
 * Abbott Copywrite 2023
 ************************************************************************************************/
const frTranslation = {
  iosAlert:"Les utilisateurs d'iOS et de Mac doivent se rendre dans la section (Paramètres)de leur navigateur et appuyer sur l'option (Ajouter à l'écran d'accueil) pour installer le logiciel.",
  installDescription:"Installez l'application sur votre appareil pour y accéder facilement à tout moment. Pas de magasin d'applications. Pas de téléchargement. Pas de soucis.",
  translation: {
    homePage: {
      muscleAgeTitle: "Connaissez-vous votre âge musculaire ?",
      exercise:
        "Relevez le défi de la chaise d'une minute pour découvrir l'âge de vos muscles maintenant !",
      selectYourAge: "Sélectionnez votre âge",
      atrophy:
        "Après l'âge de 40 ans, vous pouvez perdre jusqu'à 8% de la masse musculaire tous les 10 ans!",
      selectGender: "Sélectionnez votre genre",
      male: "Masculin",
      female: "Féminin",
      muscleAge: "Voyez votre âge musculaire",
      limitation: "Cet outil ne s'applique qu'aux personnes de plus de 40 ans.",
      muscleAgeAccessCamera: "Accéder à la caméra...",
      startYourMuscleAge: "Il est important de surveiller et de maintenir la force de vos muscles pour prévenir les chutes et continuer à vivre une vie saine et active. C'est pourquoi nous avons développé le défi du Stand de chaise - basé sur le test de 5 fois debout sur une chaise, pour aider à indiquer la force et les performances physiques de vos membres inférieurs. La session en direct numérique innovante est conçue pour utiliser la caméra de votre ordinateur pour vous guider à travers le défi et déterminer votre âge musculaire.",
    },

    cameraFallback: {
      getStartExplain:
        "Après l'âge de 40 ans, vous pouvez perdre jusqu'à 8% de la masse musculaire tous les 10 ans. Passez le test de la position assise-debout pendant 1 minutes pour découvrir quel est votre âge musculaire.",
      camera: "Pas de caméra ? Pas de problème !",
      encouraging:
        "Chronométrez-vous en faisant le défi de la Chaise et entrez vos résultats dans le formulaire pour voir quel âge ont vos muscles !",
      motivating: "Préparez-vous pour le défi.",
      completeTestRep: "Complétez une répétition de test.",
      explanatory:
        "Pour compléter le défi de la Chaise, vous devrez trouver une chaise sur laquelle vous pouvez vous asseoir et vous lever.",
      stepByStep:
        "Pour effectuer une répétition de la Chaise, avec les bras croisés sur la poitrine, vous devez vous asseoir sur la chaise et vous lever aussi vite que possible pour effectuer les 5 répétitions.",
      instructive:
        "Lorsque vous êtes prêt, chronométrez-vous pour voir combien de temps il vous faut pour terminer les 5 répétitions de la Chaise. Quand vous avez fini, entrez votre temps total ci-dessous pour obtenir l'âge de vos muscles !",
      inputTestTime: "Entrez votre temps de test (en secondes).",
      yourTime: "Votre temps de test.",
      calculateMuscle: "Calculez l'âge de vos muscles !",
      startChallenge: "Commencez le défi.",
    },

    userPreparation: {
      wait: "S'il vous plaît, attendez pendant que le modèle d'IA se charge...",
      chair: "Mettez une chaise à 1 mètre devant la caméra.",
      nextStep: "Continuer à l'étape suivante",
      notVisibleKnees: "Assurez-vous que vos genoux sont visibles!",
      description:
        "Regardez la vidéo d'instructions du test du support de chaise.",
      startTheChairStandTest: "Commencez le test de lever de chaise.",
    },

    SquatTest: {
      1: "Super ! Il reste encore 4 répétitions!",
      2: "",
      3: "Parfait ! Vous êtes presque à mi-chemin !",
      attach: "Génial, plus que 2 répétitions pour finir!",
      4: "C'est le moment, la dernière!",
      default: "Complétez les 5 répétitions aussi vite que vous pouvez!",
    },

    testCompletionMessage: {
      congratulation: "Vous avez terminé le test de la station assise !",
      processing: "Calcul de votre âge musculaire en cours...",
    },

    results: {
      shareResults: "Partagez vos résultats.",
      utterGood:
        "Excellent travail ! Il semble que l'âge de vos muscles soit plus jeune que votre âge.",
      utterBad:
        "Oh mon dieu ! Il semble que l'âge de vos muscles soit plus âgé que votre âge. Il est temps de se concentrer sur la construction de votre masse musculaire et de votre force.",
      utterOnPar:
        "Excellent travail! On dirait que votre âge musculaire correspond à votre âge",
      output: "Muscle age calculator output: ",
      footNoteFaster75:
        "Votre résultat est plus rapide que 75% de vos pairs dans la tranche d'âge {{actualAge}}.",
      footNoteSlower75:
        "Votre résultat est plus lent que 75% de vos pairs dans la tranche d'âge {{actualAge}}.",
      footNoteOnPar:
        "Votre résultat se situe entre le 25ème et le 75ème percentile par rapport à vos pairs dans la tranche d'âge de {{actualAge}}.",
      percentileBelow25:
        "Votre résultat est inférieur au 25ème percentile pour la catégorie d'âge de 40 à 44 ans.",
      percentileAbove25:
        "Votre résultat est égal ou supérieur au 25ème percentile pour la catégorie d'âge de {{outputAge}}.",
      percentileBelow75:
        "Votre résultat est égal ou inférieur au 75ème percentile pour la catégorie d'âge de {{outputAge}}.",
      unsupportedGroup: "groupe non supporté",
      invalidLinkOrUnsupportedProcess:
        "Ce lien est rompu ou le processus est incorrect, veuillez réessayer!",
      year: "ans"
    },
    
    manualTest: {
      manualbutton: "Pas de caméra ? Aucun problème ! Vous pouvez chronométrer vous-même en faisant le défi Stand de chaise et saisir votre temps dans la calculatrice manuellement. <bold> Participez au défi sans caméra ici</bold>",
      EnterDetails: "Entrez vos coordonnées dans le calculateur d'âge musculaire ci-dessous.",
      Step3: "Étape 3",
      age: "Âge",
      gender: "Genre",
      timeInSeconds: "Temps (en secondes)*",
      submit: "SOUMETTRE",
      sitToStand: "Temps assis-debout (par exemple 7,5 secondes)",
      Title: "Faites notre test pour connaître votre âge musculaire dès maintenant",
      forYourOwnSafety: "Pour votre propre sécurité",
      forYourOwnSafetyList: {
        1: "Faites attention à ne pas vous fatiguer ou vous blesser.",
        2: "Arrêtez le défi immédiatement si vos genoux commencent à faire mal.",
        3: "Ne vous penchez pas en arrière pour prendre de l'élan ou vous pourriez basculer en arrière.",
        4: "Faites le test lorsque vous avez un ami ou un membre de la famille pour vous soutenir.",
        5: "N'essayez pas le test si vous avez récemment chuté ou été blessé.",
      },
    },

    didYouKnow: "Saviez-vous?",
    calculation:
      "Le calculateur d'âge musculaire est basé sur la référence du test de lever de chaise 5 fois qui peut être une indication de la force et des performances physiques des muscles des membres inférieurs.",
    reference:
      "[1] Basé sur des mesures de masse corporelle maigre (LBM) selon Baier et al. JPEN 2009; 33: 71-82.",
  },
};

export default frTranslation;
