/***********************************************
 * Component: Home
 * Description: First page
 *
 * Copyright:
 * (c) Abbott 2023
 **********************************************/

import usePageTracking from "../../../hooks/useTracking";
import { HOME } from "../../../utils/constants/internalUrls";
import HomeOverview from "../HomeOverview";
import ClientInfo from "../clientInfo";

const Home = () => {
  usePageTracking(HOME);
  return (
    <div className="landing">
      <div className="container mx-auto">
        <div className="p-6 lg:grid grid-cols-2  flex flex-col lg:p-0 items-center gap-4 lg:h-screen">
          <HomeOverview />
          <ClientInfo />
        </div>
      </div>
    </div>
  );
};

export default Home;
