import i18next from "i18next";
import { useTranslation } from "react-i18next";

const Description = ({ message }) => {
  const language=i18next.language
  const { t } = useTranslation();

  return (
    <p className={`p-0 w-full text-center ${language==="vn"?"text-[1.5rem] leading-[1.8rem]":"max-lg:text-2xl max-sm:text-1xl text-3xl"}`}>
      {t(message)}
    </p>
  );
};

export default Description;


