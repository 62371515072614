import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import configureReduxStore from "../store/configureStore";

const { store, persistor } = configureReduxStore();

export default function ReduxProvider({ children }) {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>{children}</PersistGate>
    </Provider>
  );
}
