import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import loader from "./../../assets/Vectors/SVG/ic_circles_loading.svg";
import Congratulations from "./../../assets/Vectors/SVG/ic_congratulations.svg";
import { MUSCLE_AGE_RESULTS } from "../../utils/constants/internalUrls";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const TestCompletionMessage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const language=i18next.language
  useEffect(() => {
    const timerId = setTimeout(() => {
      navigate(MUSCLE_AGE_RESULTS);
    }, 5000);
    return () => clearTimeout(timerId);
  }, []);

  //!  Congratulation line leading
  const paragraphStyle = {
    lineHeight: language === "vn" ? (window.innerWidth > 992 ? "75px" : "40px") : "",
  };

  return (
    <box className="w-full h-[100vh] flex justify-center items-center">
      <box className="rounded-lg gap-[20px] bg-[transparent] w-[550px] h-[95%] p-[10px] text-center z-[1] overflow-hidden relative flex flex-col justify-center items-center">
        <img alt="Congratulations vector" src={Congratulations} className="max-w-[175px]"/>
        <p  style={paragraphStyle} className="w-full mb-3 mt-4 text-white flex justify-center items-center text-2xl lg:text-4xl font-bold uppercase leading-7">
          {t("testCompletionMessage.congratulation")}
        </p>
        <p className="gap-[10px] w-full mt-16 text-white flex justify-center items-center text-lg lg:text-3xl">
          {t("testCompletionMessage.processing")}
        </p>
        <box>
          <img
            fill="#fff"
            width="70"
            height="40"
            src={loader}
            alt="Animated loader icon"
          />
        </box>
      </box>
    </box>
  );
};

export default TestCompletionMessage;
