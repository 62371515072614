/*! *
 * Displayed the instructional video related to the test.
 * 
 * 
 * 
  Abbott copyright 2023
  ************************************************************************************************/
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Test_Guide } from "../../utils/constants/internalUrls";
import { event } from "../../utils/gaStatistics/gaEvent";

import audioIns from "./../../assets/audio/English/VO_instruction_En.mp3";
import audioIns_thai from "./../../assets/audio/Thailand/VO_instruction_Th.mp3";
import audioIns_es from "./../../assets/audio/Spanish/VO_instruction_Es.mp3";
import audioIns_vn from "./../../assets/audio/Vietnamese/VO_instruction_Vn.mp3";

import playIcon from "./../../assets/Vectors/SVG/ic_play_video.svg";
import arrow from "./../../assets/Vectors/SVG/ic_arrow_rigth.svg";
import usePageTracking from "../../hooks/useTracking";
import Description from "../layout/description";
import VideoModal from "./videoModal";
import i18n from "i18next";

const TestGuide = ({ setOpenSquatTest, createNewAudio }) => {
  const { t } = useTranslation();
  const { language } = i18n;
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  usePageTracking(Test_Guide);

  // Play audio instruction
  useEffect(() => {
    createNewAudio(language === "th" ? audioIns_thai :language === "es" ? audioIns_es: language==="vn"? audioIns_vn : audioIns);
  }, []);

  // open instruction video modal
  const handleOpenVideoModal = () => {
    setIsVideoModalOpen(true);
    event("Open Modal", "Modal Interactions", "video modal", 1, false);
  };

  const handleGoToSquatTest = () => {
    setOpenSquatTest(true);
    event(
      "Go to Next Step",
      "Process Navigation",
      "step: video modal to Repetition Check",
      1,
      false
    );
  };

  return (
    <>
      {isVideoModalOpen && (
        <VideoModal open={isVideoModalOpen} setOpen={setIsVideoModalOpen} />
      )}
      <Box
        className="cursor-pointer absolute flex items-start justify-center bottom-0 left-auto h-[20%] w-full bg-abbottDarkBlue rounded-t-[20px]"
        onClick={handleOpenVideoModal}
      >
        <Box className="p-[10px] h-[50%] flex justify-center items-center text-white m-0 ">
          <Box className="h-full w-full flex justify-center lg:gap-[30px] mx-2 gap-[5px] items-center cursor-pointer">
            <img
              className="cursor-pointer w-[40px]"
              src={playIcon}
              alt="Play video icon"
            />
            <Description message="userPreparation.description" />
          </Box>
        </Box>
        <button
          onClick={handleGoToSquatTest}
          className="focus:border-abbottPrimaryBlue focus:border-2 border-abbottPrimaryBlue border-solid w-full h-[50%] bg-abbottPrimaryBlue flex justify-center items-center absolute bottom-0 text-white  text-[25px] gap-[10px] hover:bg-opacity-90"
        >
          <span className="font-bold uppercase">
            {t("userPreparation.startTheChairStandTest")}
          </span>
          <img style={{ width: "20px" }} alt="next arrow icon" src={arrow} />
        </button>
      </Box>
    </>
  );
};

export default TestGuide;
