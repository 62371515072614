import { connect } from "react-redux";
import { setParamLanguage, setSelectedLanguage } from "../../../store/slices/userConfig";
import LanguageSelector from "./languageSelector";

const mapStateToProps = (state) => {
  const { language: selectedLanguage, paramLanguage } = state.entities.userConfig;
  return { selectedLanguage, paramLanguage };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedLanguage: (language) => dispatch(setSelectedLanguage(language)),
    setParamLanguage: (paramLanguage) => dispatch(setParamLanguage(paramLanguage)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
