// import ensureGoldPic from "./../../assets/Vectors/SVG/ic_ensure_gold_banner.svg";
// import ensureGoldPicCaps from "./../../assets/Vectors/SVG/ic_ensure_gold_banner_caps.svg";
import { Outlet } from "react-router-dom";
import LanguageSelector from "./languageSelector";
import { Box } from "@mui/material";
import { useState } from "react";
import { connect } from "react-redux";
import { setFullScreen } from "../../store/slices/userConfig";

//! Start component
const OutLetBackGround = ({ selectedLanguage }) => {
  //! States
  const [initialLanguageLoaded, setInitialLanguageLoaded] = useState(false);

  //^ UI section
  return (
    <Box className="backGround-component bg-gradient-to-tr from-[#009CDE] to-abbottDarkBlue relative w-full h-full min-h-[100vh]">
      <Box>
        <Box className="absolute top-[2%] right-[2%] text-white cursor-pointer">
          <LanguageSelector
            initialLanguageLoaded={initialLanguageLoaded}
            setInitialLanguageLoaded={setInitialLanguageLoaded}
          />
        </Box>
        {/* Ensure Gold logo */}
        {/*<img
          src={selectedLanguage == "vn" || selectedLanguage == "my" ? ensureGoldPicCaps : ensureGoldPic}
          alt="ensure Gold image"
          className="absolute top-0 left-0 w-36 xl:w-[10%] z-10"
        /> */}
        <Outlet key={initialLanguageLoaded} />
      </Box>
      <p className="absolute z-50 text-white bottom-2 right-2">v1.0.1</p>
    </Box>
  );
};

//! Define a function that maps the state from the Redux store to the component's props
const mapStateToProps = (state) => {
  const { isFullScreen, language: selectedLanguage } =
    state.entities.userConfig;
  return { isFullScreen, selectedLanguage };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFullScreen: (isFullScreen) => dispatch(setFullScreen(isFullScreen)),
  };
};

//! Connect the component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(OutLetBackGround);
