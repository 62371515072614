// imports
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import {
  FIVE_SQUAT_TEST,
  TEST_COMPLETION_MESSAGE,
} from "../../../utils/constants/internalUrls";
import LampIcon from "./../../../assets/Vectors/SVG/ic_lamp.svg";

import audio_Great_Rep_English from "../../../assets/audio/English/VO_great_rep_En.mp3";
import audio_Great_Rep_Spanish from "../../../assets/audio/Spanish/VO_great_rep_Es.mp3";
import audio_Great_Rep_Vietnamese from "../../../assets/audio/Vietnamese/VO_great_rep_Vn.mp3";

import audio_audioRep2_Vietnamese from "../../../assets/audio/Vietnamese/VO_testVO_05_rep2_Vn.mp3";

import audio_only_2_more_English from "../../../assets/audio/English/VO_only_2_more_En.mp3";
import audio_only_2_more_Spanish from "../../../assets/audio/Spanish/VO_only_2_more_Es.mp3";
import audio_only_2_more_Vietnamese from "../../../assets/audio/Vietnamese/VO_only_2_more_Vn.mp3";

import audio_last_one_English from "../../../assets/audio/English/VO_last_one_En.mp3";
import audio_last_one_Spanish from "../../../assets/audio/Spanish/VO_last_one_Es.mp3";
import audio_last_one_Vietnamese from "../../../assets/audio/Vietnamese/VO_last_one_Vn.mp3";

import audio_chairTest_completion_English from "../../../assets/audio/English/VO_chairTest_completion_En.mp3";
import audio_chairTest_completion_Spanish from "../../../assets/audio/Spanish/VO_chairTest_completion_Es.mp3";
import audio_chairTest_completion_Vietnamese from "../../../assets/audio/Vietnamese/VO_chairTest_completion_Vn.mp3";

import squatCounter from "../../../utils/AIEnabledCamera/counter";
import Description from "../../layout/description";
import StepsBox from "./steps";
import usePageTracking from "../../../hooks/useTracking";
import i18next from "i18next";

// SquatTest component --- start
const SquatTest = ({
  isValidPose,
  landmarkPoints,
  globalLandmarks,
  setTime,
  createNewAudio,
}) => {
  const navigate = useNavigate();
  const [startTime, setStartTime] = useState(null);

  const [counter, setCounter] = useState(0);
  const [sitting, setSitting] = useState(false);

  const [rep1Playing, setRep1Playing] = useState(false);
  const [rep2Playing, setRep2Playing] = useState(false);
  const [rep3Playing, setRep3Playing] = useState(false);
  const [rep4Playing, setRep4Playing] = useState(false);
  const [rep5Playing, setRep5Playing] = useState(false);

  const [changeMessage3, setChangeMessage3] = useState("SquatTest.3");

  usePageTracking(FIVE_SQUAT_TEST);

  const { language } = i18next;
  const isEs = language === "es";
  const isVn = language === "vn";

  useEffect(() => {
    try {
      // Perform squat counter logic
      squatCounter({
        landmarkPoints,
        globalLandmarks,
        counter,
        sitting,
        isValidPose,
        setCounter,
        setSitting,
      });

      // Handle counter change
      handleCounterChange();
    } catch (error) {
      console.error("An error occurred at SquatTest component:", error);
    }
  }, [landmarkPoints]);

  const playAudioTrack = (audio, setPlaying) => {
    createNewAudio(audio);
    setPlaying(true);
  };

  const handleCounterChange = () => {
    // Handle counter change logic
    if (counter === 0 && startTime === null) {
      handleStartTime();
    } else if (counter === 5) {
      handleEndTime();
    }

    if (counter === 1 && !rep1Playing) {
      playAudioTrack(
        isEs ? audio_Great_Rep_Spanish : isVn ? audio_Great_Rep_Vietnamese : audio_Great_Rep_English,
        setRep1Playing
      );
    } else if (counter === 2 && !rep2Playing) {
      playAudioTrack(isVn ? audio_audioRep2_Vietnamese : null, setRep2Playing);
    } else if (counter === 3 && !rep3Playing) {
      playAudioTrack(
        isEs ? audio_only_2_more_Spanish : isVn ? audio_only_2_more_Vietnamese : audio_only_2_more_English,
        setRep3Playing
      );
    } else if (counter === 4 && !rep4Playing) {
      playAudioTrack(
        isEs ? audio_last_one_Spanish : isVn ? audio_last_one_Vietnamese : audio_last_one_English,
        setRep4Playing
      );
    } else if (counter === 5 && !rep5Playing) {
      playAudioTrack(
        isEs ? audio_chairTest_completion_Spanish : isVn ? audio_chairTest_completion_Vietnamese : audio_chairTest_completion_English,
        setRep5Playing
      );
      navigate(TEST_COMPLETION_MESSAGE);
    }
  };

  const handleStartTime = () => {
    // Start timer
    const start = performance.now();
    setStartTime(start);
  };

  const handleEndTime = () => {
    const end = performance.now();
    const time = (end - startTime) / 1000.0;
    setTime(time);
  };
  useEffect(() => {
    if (counter === 3) {
      setTimeout(() => {
        setChangeMessage3("SquatTest.attach");
      }, 2000);
    }
  }, [counter]);
  const getMessage = () => {
    if (counter) {
      return `SquatTest.${counter}`;
    } else {
      return "SquatTest.default";
    }
  };

  return (
    <Box className="h-[20%] w-full bg-abbottDarkBlue bg-opacity-90 rounded-t-[20px] absolute bottom-0 left-auto flex justify-center items-start">
      <Box className="p-[10px] h-[50%] flex justify-center items-center text-white m-0 lg:gap-[20px]">
        <img className="lg:h-[100%] w-[50px] " src={LampIcon} alt="Lamp icon" />
        {counter !== 3 && <Description message={getMessage()} />}
        {counter === 3 && <Description message={changeMessage3} />}
      </Box>
      {counter < 5 && <StepsBox counter={counter} />}
    </Box>
  );
};

export default SquatTest;
