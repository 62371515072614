import { connect } from "react-redux";
import { setPoseLandmark } from "../../../store/slices/userConfig";
import Home from "./home";

const mapStateToProps = (state) => {
  const { poseLandmark } = state.entities.userConfig;
  return { poseLandmark };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPoseLandmark: (poseLandmark) => dispatch(setPoseLandmark(poseLandmark)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
