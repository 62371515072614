import { useMutation } from "@tanstack/react-query";

import { submitForm } from "../services/userFormService";

const preparedDataFormat = ({
  firstName,
  lastName,
  emailAddress,
  mobilePhone,
}) => {
  return {
    contact_firstname: firstName,
    contact_lastname: lastName,
    contact_email: emailAddress,
    contact_mobilephone: mobilePhone,
    user_type: "TRUE",
    market: "IN",
    br_leadstatus: "New Lead",
    br_mainbrandid: "IN_KB_ENS",
    cr_registrationdate: "2023-01-09",
    cr_campaignname: "IN_Ensure_Phoenix_Muscle_Age_Leads",
    cr_channelid: "CHN-WEB-00001",
  };
};

export const useFormSubmit = (formSuccessCallback) => {
  return useMutation({
    mutationFn: (formData) => submitForm(preparedDataFormat(formData)),
    onSuccess: () => formSuccessCallback(),
  });
};
