const initialState = {
  userConfig: {
    gender: "",
    age: "40-44",
    time: 0,
    cameraAccess: false,
    poseLandmark: null,
    language: "",
    paramLanguage: "",
    isFullScreen: false,
    isManualTest: false,
    personalInfoForm: {
      firstName: "",
      lastName: "",
      emailAddress: "",
      mobilePhone: "",
    },
  },
};

export default initialState;
