import { MALE } from "./../constants/constants";

const percentiles25AgeGroups = {
  male: [
    { threshold: 5.7, ageGroup: "<40" },
    { threshold: 6.0, ageGroup: "40-44" },
    { threshold: 6.3, ageGroup: "45-49" },
    { threshold: 6.5, ageGroup: "50-54" },
    { threshold: 7.0, ageGroup: "55-59" },
    { threshold: 7.2, ageGroup: "60-64" },
    { threshold: 7.7, ageGroup: "65-69" },
    { threshold: 8.1, ageGroup: "70-74" },
    { threshold: 8.8, ageGroup: "75-79" },
  ],
  female: [
    { threshold: 5.9, ageGroup: "<40" },
    { threshold: 6.0, ageGroup: "40-44" },
    { threshold: 6.2, ageGroup: "45-49" },
    { threshold: 6.7, ageGroup: "50-59" },
    { threshold: 7.0, ageGroup: "60-64" },
    { threshold: 7.2, ageGroup: "65-69" },
    { threshold: 7.7, ageGroup: "70-74" },
    { threshold: 8.2, ageGroup: "75-79" },
  ],
};

const percentiles75AgeGroups = {
  male: [
    { threshold: 8.1, ageGroup: "45-49" },
    { threshold: 8.5, ageGroup: "50-54" },
    { threshold: 9.0, ageGroup: "55-59" },
    { threshold: 9.1, ageGroup: "60-64" },
    { threshold: 9.9, ageGroup: "65-69" },
    { threshold: 10.4, ageGroup: "70-74" },
    { threshold: 11.1, ageGroup: "75-79" },
  ],

  female: [
    { threshold: 8.0, ageGroup: "45-49" },
    { threshold: 8.1, ageGroup: "50-54" },
    { threshold: 8.2, ageGroup: "55-59" },
    { threshold: 9.0, ageGroup: "60-64" },
    { threshold: 9.3, ageGroup: "65-69" },
    { threshold: 9.7, ageGroup: "70-74" },
    { threshold: 10.7, ageGroup: "75-79" },
  ],
};

const getAgeGroup = (time, ageGroups, percentileType) => {
  for (const { threshold, ageGroup } of ageGroups) {
    if (percentileType === 25) {
      if (time < Number(threshold)) return ageGroup;
    } else {
      if (time <= Number(threshold)) return ageGroup;
    }
  }
  return "+80";
};

const getResult = (
  gender,
  time,
  actualAgeGroup,
  percentile25,
  percentile75
) => {
  let utter, footnote, outputAge, percentileResult;
  switch (true) {
    case time < percentile25:
      utter = "utterGood";
      footnote =
        actualAgeGroup === "80+" ? "footNoteFaster80" : "footNoteFaster75";
      outputAge = getAgeGroup(time, percentiles25AgeGroups[gender], 25);
      if (gender === MALE) {
        percentileResult =
          time < 5.7
            ? {
                message: "percentileBelow25",
                outputAge: null,
              }
            : {
                message: "percentileAbove25",
                outputAge: outputAge,
              };
      } else {
        percentileResult =
          time < 5.9
            ? {
                message: "percentileBelow25",
                outputAge: null,
              }
            : {
                message: "percentileAbove25",
                outputAge: outputAge,
              };
      }

      break;

    case percentile25 <= time && time <= percentile75:
      utter = "utterOnPar";
      footnote = actualAgeGroup === "80+" ? "footNoteOnPar80" : "footNoteOnPar";
      outputAge = actualAgeGroup;
      percentileResult = null;
      break;

    case time > percentile75:
      utter = "utterBad";
      footnote =
        actualAgeGroup === "80+" ? "footNoteSlower80" : "footNoteSlower75";

      outputAge = getAgeGroup(time, percentiles75AgeGroups[gender], 75);
      percentileResult = {
        message: "percentileBelow75",
        outputAge: outputAge,
      };
      break;

    default:
      break;
  }

  return { utter, footnote, percentileResult, outputAge };
};

const calculateResult = (gender, time, actualAgeGroup) => {
  if (
    actualAgeGroup === "20-24" ||
    actualAgeGroup === "25-29" ||
    actualAgeGroup === "30-34" ||
    actualAgeGroup === "35-39"
  ) {
    actualAgeGroup = "40-44";
  }

  const ageGroups = {
    male: {
      "40-44": [5.7, 7.8],
      "45-49": [6.0, 8.1],
      "50-54": [6.3, 8.5],
      "55-59": [6.5, 9.0],
      "60-64": [7.0, 9.1],
      "65-69": [7.2, 9.9],
      "70-74": [7.7, 10.4],
      "75-79": [8.1, 11.1],
      "80+": [8.8, 13.5],
    },
    female: {
      "40-44": [5.9, 7.7],
      "45-49": [6.0, 8.0],
      "50-54": [6.2, 8.1],
      "55-59": [6.2, 8.2],
      "60-64": [6.7, 9.0],
      "65-69": [7.0, 9.3],
      "70-74": [7.2, 9.7],
      "75-79": [7.7, 10.7],
      "80+": [8.2, 11.2],
    },
  };

  const [percentile25, percentile75] = ageGroups[gender][actualAgeGroup] || [];

  if (!percentile25 || !percentile75) {
    console.log("unsupported");
    return null;
  }
  return getResult(gender, time, actualAgeGroup, percentile25, percentile75);
};

export default calculateResult;
