/*! **********************************************************************************************
 * This is the i18next configuration file for the application.
 * The file imports translations for English and German languages from their respective translation files.
 * The translations are added to a resources object with keys for each language.
 * The i18n instance is initialized with the resources object and configuration options for key separator and interpolation.
 * The i18n instance is then exported to be used throughout the application.
 * Abbott Copywrite 2023
 ************************************************************************************************/
import i18n from "i18next";
import auTranslation from "./au";
import esTranslation from "./es";
import enTranslation from "./en";
import thaiTranslation from "./thai";
import frTranslation from "./fr";
import viTranslation from "./vi";
import MalaysiaTranslation from "./malay";
import { initReactI18next } from "react-i18next";
import inTranslation from "./in";

/* resources Object.
 ****************************************************************************************/

let preparedInTranslation = JSON.parse(JSON.stringify(enTranslation));
preparedInTranslation.translation.results = inTranslation.translation.results;

const resources = {
  au: auTranslation,
  es: esTranslation,
  en: enTranslation,
  th: thaiTranslation,
  fr: frTranslation,
  vn: viTranslation,
  my: MalaysiaTranslation,
  in: preparedInTranslation,
};

/* This code initializes the i18n instance with configuration options.
 ****************************************************************************************/
i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
