import { connect } from "react-redux";
import ClientInfo from "./clientInfo";
import {
  setAge,
  setCameraAccess,
  setGender,
  setManualTest,
  setPersonalInformation,
} from "../../../store/slices/userConfig";

const mapStateToProps = (state) => {
  const { age, gender, personalInfoForm } = state.entities.userConfig;
  return { age, gender, personalInfoForm };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGender: (gender) => dispatch(setGender(gender)),
    setAge: (age) => dispatch(setAge(age)),
    setCameraAccess: (cameraAccess) => dispatch(setCameraAccess(cameraAccess)),
    setManualTest: (isManualTest) => dispatch(setManualTest(isManualTest)),
    setPersonalInformation: (data) => dispatch(setPersonalInformation(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientInfo);
