import { useTranslation } from "react-i18next";
import stand4strength from "./../../assets/Vectors/PNG/logo-ensure.png";

const LeftPanel = () => {
  const { t } = useTranslation();
  return (
    <div className={`text-center lg:text-left max-w-2xl mt-16 lg:mt-0 mr-0 lg:mr-8`}>
      <img
        src={stand4strength}
        alt="Stand 4 Strength"
        className="mb-4 lg:mb-12 w-32 lg:w-48 mx-auto lg:mx-0"
      />
      <div className="text-5xl text-white font-bold mb-14 lg:text-8xl">
        {t("cameraFallback.camera")}
      </div>
      <div className="text-lg lg:text-3xl text-white lg:mb-0">
        {t("cameraFallback.encouraging")}
      </div>
      <hr className="my-10 lg:my-24" />
      <div className="text-xl text-white mb-4 font-bold uppercase">
        {t("didYouKnow")}
      </div>
      <div className="text-base text-white mb-4 lg:text-2xl">
        {t("cameraFallback.getStartExplain")}
      </div>
    </div>
  );
};

export default LeftPanel;
