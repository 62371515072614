import { Box, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

const AIModelLoading = () => {
  const { t } = useTranslation();
  return (
    <Box className="w-full flex flex-col justify-center items-center gap-[5px]">
      <CircularProgress color="success" size={60} />
      <p className="text-base text-white z-[30] opacity-100 lg:text-[21px]">
        {t("userPreparation.wait")}
      </p>
    </Box>
  );
};

export default AIModelLoading;
