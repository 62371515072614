/*! *
 * This is the UserPreparation component 
 * It provides functionality to check the distance between the user and a chair and update a progress bar accordingly.
 * 
 * Features:
 * * Displays an image of a chair and the text "distance"
 * * Calculates the distance between the user and the chair using the landmarks detected by the camera
 * * Updates a progress bar based on the calculated distance
 * 
 * 
 * 
  Abbott copyright 2023
  ************************************************************************************************/
import { useState, useEffect, useRef } from "react";
import { Box, LinearProgress } from "@mui/material";

import VO_great_English from "./../../assets/audio/English/VO_great_En.mp3";
import VO_great_Thailand from "./../../assets/audio/Thailand/VO_great_Th.mp3";
import VO_great_Es from "./../../assets/audio/Spanish/VO_great_Es.mp3";
import VO_great_Vietnamese from "./../../assets/audio/Vietnamese/VO_great_Vn.mp3";

import audio_standUp_challengeIntro_English from "./../../assets/audio/English/VO_standUp_challengeIntro_En.mp3";
import audio_standUp_challengeIntro_Es from "./../../assets/audio/Spanish/VO_standUp_challengeIntro_Es.mp3";
import audio_standUp_challengeIntro_Thailand from "./../../assets/audio/Thailand/VO_standUp_challengeIntro_Th.mp3";
import audio_standUp_challengeIntro_Vietnamese from "./../../assets/audio/Vietnamese/VO_standUp_challengeIntro_Vn.mp3";

import checkValidation from "../../utils/AIEnabledCamera/checkValidation";
import chairImage from "./../../assets/Vectors/SVG/ic_chair.svg";
import usePageTracking from "../../hooks/useTracking";
import { AI_ENABLED_CAMERA } from "../../utils/constants/internalUrls";
import { Trans, useTranslation } from "react-i18next";
import i18n from "i18next";

const UserPreparation = ({
  position,
  validProgress,
  video,
  audioTrack,
  difVideoFrame,
  landmarkPoints,
  setValidProgress,
  isOpenTestGuide,
  createNewAudio,
}) => {
  const [lanMarkStatus, setLanMarkStatus] = useState(false);
  const validated = useRef(false);

  usePageTracking(AI_ENABLED_CAMERA);
  const { t } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    if (landmarkPoints !== null) setLanMarkStatus(true);
  });

  useEffect(() => {
    try {
      const waitForAudio1 = async () => {
        if (audioTrack && lanMarkStatus)
          createNewAudio(
            language === "th"
              ? audio_standUp_challengeIntro_Thailand
              : language === "es"
              ? audio_standUp_challengeIntro_Es
              : language === "vn"
              ? audio_standUp_challengeIntro_Vietnamese
              : audio_standUp_challengeIntro_English
          );
      };
      waitForAudio1();
    } catch (error) {
      console.log("An error in UserPreparation", error);
    }
  }, [lanMarkStatus]);

  //! Progress Update Use Effect
  useEffect(() => {
    const validation = checkValidation(landmarkPoints, video, position);
    if (validation[0] && !validated.current) {
      setValidProgress(
        (prevProgress) => prevProgress + (difVideoFrame * 100.0) / 6.0
      );
    }

    // Complete the validation
    const checkDistance = async () => {
      if (validProgress >= 95 && !validated.current) {
        validated.current = true;
        setValidProgress(100);
        createNewAudio(
          language === "th"
            ? VO_great_Thailand
            : language === "es"
            ? VO_great_Es
            : language === "vn"
            ? VO_great_Vietnamese
            : VO_great_English
        );
      }
    };
    checkDistance();
  }, [landmarkPoints, isOpenTestGuide]);

  return (
    <Box className="w-full bg-abbottDarkBlue bg-opacity-90 rounded-t-[15px] absolute bottom-0 left-auto">
      <Box className="px-[30px] py-[20px] w-full flex md:flex-row justify-between items-center text-white">
        <img
          className="h-[80px] mb-2 md:mb-0 flex-auto"
          src={chairImage}
          alt="chair image"
        />
        <p className="flex-auto text-center whitespace-nowrap overflow-hidden mx-[10px] ">
          -----------------------
        </p>
        <p className="p-0 flex-auto text-center text-[18px] md:leading-normal leading-tight">
          <div
            className={`md:text-[24px] ${
              language === "vn" && "uppercase text-[#ffd700]"
            } w-fit m-auto`}
          >
            {t("userPreparation.chair")}
          </div>
          <p
            className={`md:${
              language === "vn" ? "text-[19px]" : "text-[24px]"
            }`}
          >
            <Trans
              i18nKey={"userPreparation.notVisibleKnees"}
              components={{
                bold: <span className="md:text-[22px] font-bold" />,
              }}
            />
          </p>
        </p>
      </Box>
      <Box className="flex items-center justify-center w-full h-20 bg-white">
        <Box className="w-[90%] p-0 m-0 z-[20]">
          <Box className="flex justify-between items-center w-full text-[25px]  text-[#63666A] gap-[5px]">
            <LinearProgress
              sx={{ height: 15, borderRadius: "10px" }}
              className="w-[87%] rounded-b-md"
              variant="buffer"
              valueBuffer={0}
              value={validProgress}
              color="secondary"
            />
            <span className="">{Math.round(validProgress)}%</span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserPreparation;
