import React from "react";
import ReactDOM from "react-dom/client";
import App from "./container/App";
import ErrorBoundary from "./container/errorBoundary";
import i18n from "./assets/i18n/index";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ErrorBoundary>
);


