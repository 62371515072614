export const YOUTUBE_EMBED = "https://www.youtube.com/embed/8jpAVXqE-xA";
export const MUSCLE_CALCULATOR_VIDEO =
  "https://static-pa.trakphysioapi.com/rehab/abbott/muscle_calculator_short_video.mp4";

export const MUSCLE_CALCULATOR_VIDEO_vn = "https://vimeo.com/954345304";

export const ART_BOARD_PNG =
  "https://static-pa.trakphysioapi.com/rehab/abbott/Muscle+Age+2_Artboard.png";

export const USER_INFORMATION_API =
  "https://apse1-apigw.dm-ap.informaticacloud.com/t/antiger.prod/UnifiedAPI-Process-IN";

export const MY_SHARE_DOMAIN =
  "https://www.family.abbott/my-en/ensure/tools-and-resources/tools/muscle-age-calculator.html";

export const AU_SHARE_DOMAIN =
  "https://family.abbott/au-en/ensure/tools-and-resources/muscle-age-calculator.html";

export const DEFAULT_DOMAIN =
  "https://family.abbott/au-en/ensure/tools-and-resources/muscle-age-calculator.html";
