// This function converts radians to degrees.
import { LEFT, RIGHT } from "./../constants/constants";
function degrees(radians) {
  return radians * (180 / Math.PI);
}

// This function checks the validity of the detected landmarks and returns a boolean value accordingly
function checkValidation(landMarkerResult, video, position) {
  if (landMarkerResult === null) {
    return [false, 1];
  }

  // Get the x and y coordinates of various landmarks.
  // These landmarks are used for validation checks later on.
  var head_x = landMarkerResult[0][0].x;
  var head_y = landMarkerResult[0][0].y;

  var rShoulder_x = landMarkerResult[0][11].x;
  var rShoulder_y = landMarkerResult[0][11].y;

  var lShoulder_x = landMarkerResult[0][12].x;
  var lShoulder_y = landMarkerResult[0][12].y;

  var rHip_x = landMarkerResult[0][23].x;
  var rHip_y = landMarkerResult[0][23].y;

  var lHip_x = landMarkerResult[0][24].x;
  var lHip_y = landMarkerResult[0][24].y;

  var rKnee_x = landMarkerResult[0][25].x;
  var rKnee_y = landMarkerResult[0][25].y;

  var lKnee_x = landMarkerResult[0][26].x;
  var lKnee_y = landMarkerResult[0][26].y;

  var rAnkle_x = landMarkerResult[0][27].x;
  var rAnkle_y = landMarkerResult[0][27].y;

  var lAnkle_x = landMarkerResult[0][28].x;
  var lAnkle_y = landMarkerResult[0][28].y;

  let ShoulderPlaceY = rShoulder_y - lShoulder_y;

  // initial validation - sanity check
  // Perform an initial validation check on the detected landmarks.
  // If the check fails, return false.
  if (
    head_x <= lShoulder_x ||
    head_x >= rShoulder_x ||
    head_y >= rShoulder_y ||
    head_y >= lShoulder_y ||
    head_y >= rHip_y ||
    head_y >= lHip_y ||
    head_y >= rKnee_y ||
    head_y >= lKnee_y ||
    head_y >= rAnkle_y ||
    head_y >= lAnkle_y ||
    rShoulder_y >= rHip_y ||
    // check
    ShoulderPlaceY > 0.3 ||
    ShoulderPlaceY < -0.3 ||
    
    lShoulder_y >= lHip_y ||
    rShoulder_x <= lShoulder_x ||
    rKnee_x <= lKnee_x ||
    rAnkle_x <= lAnkle_x ||
    rHip_x <= lHip_x ||
    rHip_y > rKnee_y ||
    lHip_y > lKnee_y ||
    rKnee_y >= rAnkle_y ||
    lKnee_y >= lAnkle_y ||
    lAnkle_x >= rAnkle_x ||
    lHip_x >= rHip_x ||
    lKnee_x >= rKnee_x
  ) {
    // console.log("init validation failed!............");
    return [false, 2];
  }

  // validation 1
  // Perform validation 1: make sure all joints are within the video frame.
  // If any joint is outside the frame, return false.
  var targetJoints = [
    11, 12, 13, 14, 23, 24, 25, 26,
  ];

  let isValid = true;
  targetJoints.forEach((jointIndex) => {
    var joint = landMarkerResult[0][jointIndex];
    if (joint.x <= 0 || joint.x >= 1 || joint.y <= 0 || joint.y >= 1) {
      isValid = false;
    }
  });
  if (!isValid) {
    // console.log("validation 1 failed")
    return [false, 3];
  }

  // validation 2
  // Perform validation 2: validate based on the position of the person in the frame.
  // If any joint has visibility less than 0.8, return false.
  var Front_checkList = [0, 11, 12, 23, 24, 25, 26, 27, 28];
  var Left_checkList = [0, 11, 23, 25, 27];
  var Rigth_checkList = [0, 12, 24, 26, 28];

  let checkList;
  if (position === RIGHT) checkList = Rigth_checkList;
  else if (position === LEFT) checkList = Left_checkList;
  else checkList = Front_checkList;

  checkList.forEach((jointIndex) => {
    var joint = landMarkerResult[0][jointIndex];
    if (joint.visibility < 0.8) {
      // console.log("second validation failed!");
      return [false, 4];
    }
  });

  // validation 3
  // Perform validation 3: validate the angle between left shoulder and left hip.
  // If the angle is less than 65 degrees, return false.
  var shoulderX;
  var shoulderY;
  var hipX;
  var hipY;

  if (position === RIGHT) {
    shoulderX = rShoulder_x * video.videoWidth;
    shoulderY = rShoulder_y * video.videoHeight;

    hipX = rHip_x * video.videoWidth;
    hipY = rHip_y * video.videoHeight;
  } else {
    shoulderX = lShoulder_x * video.videoWidth;
    shoulderY = lShoulder_y * video.videoHeight;

    hipX = lHip_x * video.videoWidth;
    hipY = lHip_y * video.videoHeight;
  }

  var x_diff = hipX - shoulderX;
  var y_diff = hipY - shoulderY;
  var angle = degrees(Math.atan2(y_diff, x_diff));
  angle = Math.abs(angle);

  if (angle > 90) angle = Math.abs(180 - angle);

  if (angle < 55) {
    // console.log("third validation failed!, " + angle);
    return [false, 5];
  }

  return [true, 0];
}

export default checkValidation;
