import { connect } from "react-redux";

import { setTime } from "../../../store/slices/userConfig";
import SquatTest from "./SquatTest";

const mapDispatchToProps = (dispatch) => {
  return {
    setTime: (time) => dispatch(setTime(time)),
  };
};

export default connect(null, mapDispatchToProps)(SquatTest);
