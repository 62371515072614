import { combineReducers } from "redux";
import userConfigReducer from "./slices/userConfig";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["time"],
};

const appReducer = combineReducers({
  userConfig: persistReducer(persistConfig, userConfigReducer),
});

export default (state, action) => {
  return appReducer(state, action);
};
