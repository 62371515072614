import { connect } from "react-redux";
import InstructionsModal from "./instructionsModal";
import { setCameraAccess } from "../../../../store/slices/userConfig";

const mapStateToProps = (state) => {
  const { isManualTest, cameraAccess } = state.entities.userConfig;
  return { isManualTest, cameraAccess };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCameraAccess: (cameraAccess) => dispatch(setCameraAccess(cameraAccess)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InstructionsModal);
