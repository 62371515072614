import { connect } from "react-redux";
import { setAge, setGender, setTime } from "../../../store/slices/userConfig";
import RightPanel from "./rightPanel";

const mapStateToProps = (state) => {
  const { age, gender,time } = state.entities.userConfig;
  return { age, gender,time };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGender: (gender) => dispatch(setGender(gender)),
    setAge: (age) => dispatch(setAge(age)),
    setTime: (time) => dispatch(setTime(time)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RightPanel);
