/*! **********************************************************************************************
 * The playAudio function takes a path and an audioTrack as parameters. It attempts to play an audio track.
 *
 *
 *
 * Abbott copyright 2023
 ************************************************************************************************/

const playAudio = (path, audioTrack) => {
  try {
    // *Condition---Check if audioTrack exists and is not paused
    if (audioTrack && !audioTrack.paused) {
      audioTrack.pause();
    }

    if (audioTrack) {
      audioTrack.src = path;
      const audioPromise = audioTrack.play();

      // *Condition--- Check if audioPromise is defined
      if (audioPromise) {
        audioPromise
          .then(() => {
            console.log("Playing: " + path);
          })
          .catch((err) => {
            console.info("Error playing audio: " + err + ", path: " + path);
          });
      }
    }
  } catch (error) {
    console.error("An error in playAudio component", error);
  }
};

export { playAudio };
