import { ENVIRONMENT, USER } from "../../utils/constants/constants";
import switchCam from "./../../assets/Vectors/SVG/ic_switch_camera.svg";
const SwitchCamera = ({ setCameraMode }) => {
  return (
    <img
    src={switchCam}
    className="absolute top-[10px] z-[60] right-[10px] w-[40px] text-[40px] cursor-pointer bg-abbottDarkBlue bg-opacity-60 rounded-md p-[3px]" alt={"Switch camera"} onClick={() => {
      setCameraMode((prevPosition) => {
        return prevPosition === USER ? ENVIRONMENT : USER;
      });
    }}/>
   
  );
};

export default SwitchCamera;
