/*! **********************************************************************************************
 * This code snippet imports the handleFrontPosition function and defines a squatCounter function that utilizes it to update a counter value based on certain conditions.
 *
 *
 *
 * Abbott copyright 2023
 ************************************************************************************************/
import handleFrontPosition from "./PoseUtils/frontPose";

function squatCounter({
  landmarkPoints,
  globalLandmarks,
  counter,
  sitting,
  isValidPose,
  setCounter,
  setSitting,
}) {
  try {
    if (
      landmarkPoints !== null &&
      globalLandmarks !== null &&
      isValidPose
    ) {
      let tempCounter = handleFrontPosition(
        counter,
        sitting,
        setSitting,
        globalLandmarks
      );
      setCounter(tempCounter.counter);
    }
  } catch (error) {
    console.log(error);
  }
}
export default squatCounter;
