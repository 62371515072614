import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import i18next from "i18next";
import FormSectionLabel from "../../common/formSectionLabel";

const AgeSelector = ({ age, setAge }) => {
  const { t } = useTranslation();
  const { language } = i18next;
  const handleChange = (e) => {
    const selectedAge = e.target.value;
    setAge(selectedAge);
    window.dataLayer.push({
      event: "select_age",
      age: selectedAge
    });
  };

  return (
    <div
      className={`mb-2 w-full ${
        language === "vn" ? "font-san" : "font-brandon"
      }`}
    >
      <FormSectionLabel label="homePage.selectYourAge" />
      <div className="flex flex-row">
        <Box className="w-full" sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel
              sx={{
                backgroundColor: "white",
                fontSize: "20px",
                padding: "0 5px",
                fontFamily: language === "vn" ? "font-san" : "brandon",
              }}
              id="demo-simple-select-label"
            >
              {t("homePage.selectYourAge")}
            </InputLabel>
            <Select
              sx={{ minWidth: 120, width: "100%" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              label="Age"
              onChange={handleChange}
              className="rounded-lg "
              style={{ fontFamily: language === "vn" ? "font-san" : "brandon" }}
            >
              <MenuItem
                style={{
                  fontFamily: language === "vn" ? "font-san" : "brandon",
                }}
                value="20-24"
              >
                20-24
              </MenuItem>
              <MenuItem
                style={{
                  fontFamily: language === "vn" ? "font-san" : "brandon",
                }}
                value="25-29"
              >
                25-29
              </MenuItem>
              <MenuItem
                style={{
                  fontFamily: language === "vn" ? "font-san" : "brandon",
                }}
                value="30-34"
              >
                30-34
              </MenuItem>
              <MenuItem
                style={{
                  fontFamily: language === "vn" ? "font-san" : "brandon",
                }}
                value="35-39"
              >
                35-39
              </MenuItem>
              <MenuItem
                style={{
                  fontFamily: language === "vn" ? "font-san" : "brandon",
                }}
                value="40-44"
              >
                40-44
              </MenuItem>
              <MenuItem
                style={{
                  fontFamily: language === "vn" ? "font-san" : "brandon",
                }}
                value="45-49"
              >
                45-49
              </MenuItem>
              <MenuItem
                style={{
                  fontFamily: language === "vn" ? "font-san" : "brandon",
                }}
                value="50-54"
              >
                50-54
              </MenuItem>
              <MenuItem
                style={{
                  fontFamily: language === "vn" ? "font-san" : "brandon",
                }}
                value="55-59"
              >
                55-59
              </MenuItem>
              <MenuItem
                style={{
                  fontFamily: language === "vn" ? "font-san" : "brandon",
                }}
                value="60-64"
              >
                60-64
              </MenuItem>
              <MenuItem
                style={{
                  fontFamily: language === "vn" ? "font-san" : "brandon",
                }}
                value="65-69"
              >
                65-69
              </MenuItem>
              <MenuItem
                style={{
                  fontFamily: language === "vn" ? "font-san" : "brandon",
                }}
                value="70-74"
              >
                70-74
              </MenuItem>
              <MenuItem
                style={{
                  fontFamily: language === "vn" ? "font-san" : "brandon",
                }}
                value="75-79"
              >
                75-79
              </MenuItem>
              <MenuItem
                style={{
                  fontFamily: language === "vn" ? "font-san" : "brandon",
                }}
                value="80+"
              >
                80+
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </div>
    </div>
  );
};

export default AgeSelector;
