/***********************************************
 * top_down⬆⬇
 * Component: HomeOverview
 * Description: Muscle Age Insights
 * _UI
 * Copyright:
 * (c) Abbott 2023
 **********************************************/
import i18n from "i18next";
import { Trans, useTranslation } from "react-i18next";
import stand4strength from "./../../assets/Vectors/PNG/logo-ensure.png";

// ==============================
// start component
// ==============================
const HomeOverview = () => {
  const { t } = useTranslation();
  const { language } = i18n;

  // ==============================
  // _UI
  // ==============================
  return (
    <div
      className={`text-center lg:text-left flex flex-col justify-center max-w-2xl mt-16 lg:mt-0 font-brandon`}
    >
      <img
        src={stand4strength}
        alt="Stand 4 Strength"
        className="mb-4 lg:mb-12 w-32 lg:w-48 mx-auto lg:mx-0"
      />
      <div className="text-white font-brandon font-bold mb-14 xl:text-8xl lg:text-7xl md:text-6xl text-5xl">
        {t("homePage.muscleAgeTitle")}
      </div>
      <div className="text-xl text-white mb-4 font-bold uppercase ">
        {t("didYouKnow")}
      </div>
      <div className="text-base text-white lg:text-2xl ">
        <Trans
          i18nKey={"homePage.atrophy"}
          components={{ superScript: <sup className="sups" /> }}
        />
      </div>
      {
        language!=="vn"&&
        <div className="text-base text-white mt-12 max-lg:text-[12px] lg:text-[16px]">
        <Trans
        i18nKey={"homePage.startYourMuscleAge"}
        components={{ superScript: <sup className="sups" /> }}
      />
        </div>
      }
      
      <div className="text-base text-white mt-12 lg:text-2xl">
        {t("homePage.exercise")}
      </div>
    </div>
  );
};

export default HomeOverview;
// ==============================
// top_down⬆⬇
// ==============================
