/*! *
* This component sets the poseLandmark model by creating a video tag and a canvas.
*
*
*
Abbott copyright 2023
************************************************************************************************/

/* <--------------Imports--------------> */

/** Imports----> Libraries **/
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Box } from "@mui/material";

/** Imports----> Model **/
import AIModelLoading from "./AIModelLoading";
import {
  DrawingUtils,
  FilesetResolver,
  PoseLandmarker,
} from "@mediapipe/tasks-vision";

/** Imports----> Components **/
import SwitchCamera from "./switchCamera";
import SquatTest from "./SquatTest";
import UserPreparation from "./UserPreparation";
import getUserPosition from "../../utils/AIEnabledCamera/getUserPosition";
import checkValidation from "../../utils/AIEnabledCamera/checkValidation";
import TestGuide from "./TestGuide";
import CountDownTimer from "./timer/countDownTimer";
import CountUpTimer from "./timer/countUpTimer";
import { event } from "../../utils/gaStatistics/gaEvent";
import { playAudio } from "../../utils/AIEnabledCamera/playAudio";
import { CAMERA_FALLBACK } from "../../utils/constants/internalUrls";
import {
  DISTANCE_PROGRESS_THRESHOLD,
  FRONT,
  USER,
} from "../../utils/constants/constants";
import arrow from "./../../assets/Vectors/SVG/ic_arrow_rigth.svg";
import { setFullScreen } from "../../store/slices/userConfig";
import zoomIn from "./../../assets/Vectors/SVG/ic_zoom_in.svg"
import zoomOut from "./../../assets/Vectors/SVG/ic_zoom_out.svg"
import backIcom from "./../../assets/Vectors/SVG/ic_back_icom.svg"

const InitialSetup = ({isFullScreen,setFullScreen}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const canvasRef = useRef(null);
  const videoRef = useRef(null);
  /* <--------------States--------------> */
  /** AI model states **/
  const [globalLandmarks, setGlobalLandmarks] = useState(null);
  const [landmarkPoints, setLandmarkPoints] = useState(null);
  const [landmarksLoading, setLandmarksLoading] = useState(true);
  const [poseLandmark, setPoseLandmark] = useState(null);
  // const [drawingUtils, setDrawingUtils] = useState(null);
  const [isValidPose, setIsValidPose] = useState(false);
  const [position, setPosition] = useState(FRONT);

  /** Components states **/
  const [validProgress, setValidProgress] = useState(0.0);
  const [isOpenTestGuide, setOpenTestGuide] = useState(false);
  const [isOpenSquatTest, setOpenSquatTest] = useState(false);
  const [showSquatTimer, setShowSquatTimer] = useState(false);

  /** Camera/audio/video states **/
  const [difVideoFrame, setDifVideoFrame] = useState(0);
  const [cameraMode, setCameraMode] = useState(USER);
  const [videoState, setVideoState] = useState();
  const [audioTrack, setAudioTrack] = useState();
  // const [drawingUtils, setDrawingUtils] = useState(null);
  /** Initialize the audio object **/
  useEffect(() => {
    try {
      const newAudio = new Audio();
      newAudio.controls = true;
      setAudioTrack(newAudio);
    } catch (error) {
      console.error("An error occurred during audio initialization:", error);
    }
  }, []);

  const createNewAudio = (path) => {
    if (audioTrack) {
      /** Call the playAudio function with the specified path and audioTrack **/
      playAudio(path, audioTrack);
    }
  };
const offlineModel = '/pose_landmarker_lite.task'
const VISION_TASK='/wasm'
  /** Initialize the PoseLandmark. **/
  useEffect(() => {
    try {
      const initializePoseLandmark = async () => {
        const vision = await FilesetResolver.forVisionTasks(VISION_TASK);
        const poseLandmark = await PoseLandmarker.createFromOptions(vision, {
          baseOptions: {
            modelAssetPath: offlineModel,
            delegate: "GPU",
          },
          runningMode: "LIVE_STREAM",
          numPoses: 1,
        });
        setPoseLandmark(poseLandmark);
      };
      initializePoseLandmark();
    } catch (error) {
      console.error(
        "An error occurred during PoseLandmark initialization",
        error
      );
    }
  }, []);

  const stopVideoStream = () => {
    if (window?.localStream) {
      window.localStream.getTracks().forEach(track => track?.stop());
      window.localStream = null;
    }
  };
  /** Initialize camera recording and pose detection. **/
  useEffect(() => {
    const videoElement = videoRef.current;
    setVideoState(videoElement);
    try {
      /* Function to start camera recording and pose detection */
      const startCameraRecording = async (videoElement) => {
        const constraints = {
          video: {
            facingMode: cameraMode,
          },
        };

        await navigator.mediaDevices
          .getUserMedia(constraints)
          .then(async (stream) => {
            window.localStream = stream;
            videoElement.srcObject = stream;
            setVideoState(videoElement);
          })
          .catch((error) => {
            navigate(CAMERA_FALLBACK);
            console.error("Loading camera was error:", error);
          });
      };

      startCameraRecording(videoElement);
    } catch (error) {
      console.error("Loading camera was error:", error);
    }
    return () => {
      // Cleanup video and audio streams
      stopVideoStream();
    }
  }, [cameraMode]);

    /** Initialize the canvas. **/
    // useEffect(() => {
    //   const initializeCanvas = () => {
    //     try {
    //       const canvasElement = canvasRef.current;
    //       const canvasCtx = canvasElement.getContext("2d");
    //       setDrawingUtils(new DrawingUtils(canvasCtx));
    //       console.log("Canvas created successfully");
    //     } catch (error) {
    //       console.error("An error occurred while creating the canvas:", error);
    //     }
    //   };
  
    //   initializeCanvas();
    // }, [canvasRef.current]);

  /** Initialize the canvas. **/
  useEffect(() => {
    const videoElement = videoRef.current;
    const canvasElement = canvasRef.current;

    if (!videoElement || !canvasElement) return;

    const resizeCanvas = () => {
      
      const videoWidth = videoElement.videoWidth;
      const videoHeight = videoElement.videoHeight;
      const videoAspectRatio = videoWidth / videoHeight;
      const canvasHeight = videoElement.offsetHeight;
    
        canvasElement.width = canvasHeight * videoAspectRatio;
        canvasElement.height = canvasHeight;
    };

    // Add event listeners to resize the canvas when the video metadata is loaded and when the window is resized
    videoElement.onloadedmetadata = resizeCanvas;
    window.addEventListener('resize', resizeCanvas);

    // Call resizeCanvas initially to set the canvas size
    resizeCanvas();

    return () => {
      // Clean up event listeners when the component is unmounted
      videoElement.onloadedmetadata = null;
      window.removeEventListener('resize', resizeCanvas);
    };

  }, []);

  /** This function performs webcam pose detection and updates state variables based on detected landmarks in real-time. **/
  useEffect(() => {
    const canvasElement = canvasRef.current;
    const videoElement = videoRef.current;
    const canvasContext = canvasElement.getContext('2d');
    let lastVideoTime = -1;


    const detectPose = async () => {
      try {
        if (lastVideoTime !== videoElement.currentTime) {
          setDifVideoFrame(videoElement.currentTime - lastVideoTime);
          lastVideoTime = videoElement.currentTime;
          if (
            poseLandmark &&
            videoElement.readyState >= videoElement.HAVE_CURRENT_DATA
          ) {
            setLandmarksLoading(false);
            const startTimeMs = performance.now();

            poseLandmark.detectForVideo(videoElement, startTimeMs, (result) => {
              if (
                result &&
                result?.landmarks &&
                Object.keys(result.landmarks).length > 0
              ) {

                const userPosition = getUserPosition(result.landmarks, canvasElement?.width);
                setLandmarkPoints(result.landmarks);
                setGlobalLandmarks(result.worldLandmarks);
                setPosition(userPosition);
                setIsValidPose(
                  checkValidation(result.landmarks, videoElement, position)[0]
                );
              }

              // Clear the canvas (fill with a transparent color) before drawing
              canvasContext.clearRect(0, 0, canvasElement.width, canvasElement.height);
              // for (const landmark of result?.landmarks) {
              //   drawingUtils.drawLandmarks(landmark, {
              //     radius: 3,
              //   });
              //   drawingUtils?.drawConnectors(
              //     landmark,
              //     PoseLandmarker?.POSE_CONNECTIONS
              //   );
              // }
            });
          }
        }
        window.requestAnimationFrame(detectPose);
      } catch (error) {
        console.error("An error occurred while predicting webcam:", error);
      }
    };
    detectPose();
  }, [poseLandmark, cameraMode]);

  /** Open testGuide component **/
  const goToTestGuide = () => {
    setOpenTestGuide(true);
    event(
      "Go to Next Step",
      "Process Navigation",
      "step: userPreparation to videoModal",
      1,
      false
    );
  };

  /** Handle back to main page **/
  const handleBackProcess = () => {
    stopVideoStream();
    // window.localStream.getVideoTracks()[0].stop()
    videoState.src=''
    setVideoState('')
    if (audioTrack) {
      audioTrack.pause();
      audioTrack.currentTime = 0;
    }
    navigate("/");
  };

  /* <--------------UI Start Point--------------> */

  const renderComponents = () => {
    /** SquatTest component **/
    if (isOpenSquatTest)
      return (
        showSquatTimer && (
          <SquatTest
            position={position}
            landmarkPoints={landmarkPoints}
            createNewAudio={createNewAudio}
            globalLandmarks={globalLandmarks}
            isValidPose={isValidPose}
          />
        )
      );

    /** TestGuide component **/
    if (isOpenTestGuide)
      return (
        <TestGuide
          setOpenSquatTest={setOpenSquatTest}
          createNewAudio={createNewAudio}
        />
      );

    if (validProgress > DISTANCE_PROGRESS_THRESHOLD)
      return (
        <button
          onClick={() => goToTestGuide()}
          className="focus:border-abbottPrimaryBlue focus:border-2 border-abbottPrimaryBlue border-solid flex justify-center items-center w-full h-[10%] absolute bottom-0 left-0 text-white bg-abbottPrimaryBlue hover:bg-opacity-90 text-[25px] gap-[10px]"
        >
          <span className="font-bold uppercase">{t("userPreparation.nextStep")}</span>
          <img src={arrow} alt="Next arrow icon" />
        </button>
      );

    /** UserPreparation component **/
    return (
      <UserPreparation
        video={videoState}
        position={position}
        landmarkPoints={landmarkPoints}
        setValidProgress={setValidProgress}
        validProgress={validProgress}
        audioTrack={audioTrack}
        difVideoFrame={difVideoFrame}
        isOpenTestGuide={isOpenTestGuide}
        createNewAudio={createNewAudio}
      />
    );
  };

  //! When the Esc button is clicked, seFullScreen is updated.  
  if (document.addEventListener) {
    document.addEventListener('webkitfullscreenchange', exitHandler, false);
    document.addEventListener('mozfullscreenchange', exitHandler, false);
    document.addEventListener('fullscreenchange', exitHandler, false);
    document.addEventListener('MSFullscreenChange', exitHandler, false);
}
function exitHandler() {
  if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement && isFullScreen) {
    setFullScreen(!isFullScreen)
  }
}

// Main ui
  return (
    <Box className="w-full h-[100dvh] flex justify-center items-center overflow-hidden">
    
    <Box className="w-full md:w-[750px] min-w-[400px] h-full lg:h-[85%] z-1  bg-white lg:rounded-2xl overflow-hidden relative ">
    {/* Switch Camera */}
        <SwitchCamera setCameraMode={setCameraMode} />
        <img
        alt="fullscreen" 
        className="absolute top-[10px] right-[70px] w-[35px] text-[40px] cursor-pointer z-40 bg-abbottDarkBlue bg-opacity-60 rounded-md p-[3px]" src={!isFullScreen ? zoomIn : zoomOut}         
        onClick={() => {
          // Exit fullscreen
          if(isFullScreen){
            if (document.exitFullscreen) {
              document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
              document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
              document.webkitExitFullscreen();
            }else if (document.msExitFullscreen) {
              /* IE/Edge */
              document.msExitFullscreen();
            }  
          }else{
            if (document?.body?.requestFullscreen) {
              document.body.requestFullscreen();
            } else if (document?.documentElement?.requestFullscreen) {
              document.documentElement.requestFullscreen();
            }
          }
          setFullScreen(!isFullScreen)
}}/>

        <Box  className="w-full h-full flex justify-center items-center bg-abbottDarkBlue  relative pb-[5rem]">
          {/* Back Icon */}
          <img onClick={handleBackProcess} alt="Back icon" className="cursor-pointer absolute w-[35px] h-[35px] top-[3%] max-lg:top-[10%] left-[2%] max-sm:left-4  z-20" src={backIcom}/>
          <Box className="absolute translate-x-[-50%] translate-y-[-50%] top-[50%] left-[50%] h-fit  w-full ">
            {/* AI Model Loading */}
            {landmarksLoading && <AIModelLoading />}
          </Box>
          {/* Video */}
          <video
            id="webcam"
            ref={videoRef}
            style={{
              opacity: landmarksLoading ? 0.1 : 1,
            }}
            autoPlay
            playsInline
            className="absolute top-0 left-0 w-full h-full  object-cover pb-[5rem]"
          />
          {/* Output Canvas */}
          <canvas
            id="output_canvas"
            ref={canvasRef}
            className="absolute top-0 left-0 w-full h-full  object-cover pb-[5rem]"
          />
          {/* Squat Test Countdown Timer*/}
          {isOpenSquatTest && !showSquatTimer && (
            <CountDownTimer
              setShowSquatTimer={setShowSquatTimer}
              createNewAudio={createNewAudio}
            />
          )}
          {/* Squat Test CountUp Timer*/}
          {showSquatTimer && <CountUpTimer showSquatTimer={showSquatTimer} />}
        </Box>
        {renderComponents()}
      </Box>
    </Box>
  );
};

const mapStateProps = (state) => {
  const { poseLandmark } = state.entities.userConfig;
  const { isFullScreen } = state.entities.userConfig;
  return { poseLandmark,isFullScreen };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFullScreen: (isFullScreen) => dispatch(setFullScreen(isFullScreen)),
  };
};
export default connect(mapStateProps, mapDispatchToProps)(InitialSetup);
