import { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-container bg-[#f8d7da] border-[1px] border-solid border-[#f5c6cb] rounded-[4px] p-[16px] w-full flex justify-center items-center">
          <h1 className="w-fit h-fit">Oops! Something went wrong</h1>
        </div>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
