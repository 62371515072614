const pixelIds = {
    CHILE: process.env.REACT_APP_META_PIXEL_ID_CHILE,
    COLOMBIA: process.env.REACT_APP_META_PIXEL_ID_COLOMBIA,
    CADR: process.env.REACT_APP_META_PIXEL_ID_CADR,
    ARGENTINA: process.env.REACT_APP_META_PIXEL_ID_ARGENTINA,
    PERU: process.env.REACT_APP_META_PIXEL_ID_PERU,
  };
  
  const countryToRegion = {
    CL: 'CHILE',
    CO: 'COLOMBIA',
    AR: 'ARGENTINA',
    PE: 'PERU',
    CR: 'CADR',
  };
  
  export const initializeMetaPixel = (countryCode) => {
    const region = countryToRegion[countryCode] || 'CADR'; // Default to CADR if country not found
    const pixelId = pixelIds[region];
    if (pixelId && window.fbq) {
      window.fbq('init', pixelId);
      window.fbq('track', 'PageView');
      
      // Update noscript pixel
      const noscriptPixel = document.querySelector('noscript img[src^="https://www.facebook.com/tr"]');
      if (noscriptPixel) {
        noscriptPixel.src = `https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`;
      }
    }
  };
  
  export const trackMetaPixelEvent = (eventName, eventData = {}) => {
    if (window.fbq) {
      window.fbq('track', eventName, eventData);
    }
  };