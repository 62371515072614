import { useEffect, useState } from "react";

const CountUpTimer = ({ showSquatTimer }) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (showSquatTimer) {
      const intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [showSquatTimer]);

  return (
    <p className={`text-[40px] text-white absolute top-10 left-auto`}>
      {seconds}
    </p>
  );
};

export default CountUpTimer;
