import { FRONT, LEFT, RIGHT } from "../constants/constants";

function getUserPosition(landmarks, imageWidth) {
  const noseX = landmarks[0][0]?.x * imageWidth;
  const leftShoulderX = landmarks[0][11]?.x * imageWidth;
  const rightShoulderX = landmarks[0][12]?.x * imageWidth;

  if (noseX < leftShoulderX && noseX > rightShoulderX) return FRONT;
  if (noseX < leftShoulderX && noseX < rightShoulderX) return LEFT;
  if (noseX > leftShoulderX && noseX > rightShoulderX) return RIGHT;

  // console.log("The body position is unclear. ");
}

export default getUserPosition;
