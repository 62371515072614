import { connect } from "react-redux";
import MuscleAgeResults from "./muscleAgeResults";
import { setFullScreen } from "../../../store/slices/userConfig";

const mapStateToProps = (state) => {
  const { age, gender, time, isFullScreen, cameraAccess } =
    state.entities.userConfig;
  return { age, gender, time, isFullScreen, cameraAccess };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFullScreen: (isFullScreen) => dispatch(setFullScreen(isFullScreen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MuscleAgeResults);
