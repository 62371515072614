export default function validateForm({
  firstName,
  lastName,
  emailAddress,
  mobilePhone,
}) {
  const errors = {
    firstName: validateInput("firstName", firstName),
    lastName: validateInput("lastName", lastName),
    emailAddress: validateInput("emailAddress", emailAddress),
    mobilePhone: validateInput("mobilePhone", mobilePhone),
  };

  return errors;
}

export function validateInput(name, value) {
  switch (name) {
    case "firstName":
    case "lastName":
      return !value
        ? "requiredField"
        : /^[a-zA]+$/.test(value)
        ? ""
        : "invalidField";
    case "emailAddress":
      return !value
        ? "requiredField"
        : /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)
        ? ""
        : "invalidField";
    case "mobilePhone":
      return !value
        ? "requiredField"
        : /^(?:\+91|0)?[6789]\d{9}$/.test(value)
        ? ""
        : "invalidField";
    default:
      return "";
  }
}
