const esTranslation = {
  translation: {
    iosAlert: "Los usuarios de IOS y Mac deben navegar a la sección 'Configuración' de su navegador y tocar la opción 'Agregar a la pantalla de inicio' para instalar el software.",
    installDescription: "Instale la aplicación en su dispositivo para acceder fácilmente en cualquier momento. Sin tienda de aplicaciones. Sin descargas. Sin complicaciones.",
    homePage: {
      muscleAgeTitle: "Descubre tu edad muscular",
      exercise: "¡Realiza el Desafío de 1 minuto de Sentarse y Levantarse para descubrir tu edad muscular ahora!",
      atrophy: "Después de los 40 años, puedes perder hasta un 8% de masa muscular por década [1].",
      selectYourAge: "Selecciona tu edad",
      selectGender: "Selecciona tu género",
      male: "Hombre",
      female: "Mujer",
      muscleAge: "Ver tu edad muscular",
      limitation: "Esta herramienta solo es aplicable para personas mayores de 40 años",
      muscleAgeAccessCamera: "Accediendo a la cámara...",
      startYourMuscleAge: "Es importante monitorear y mantener tu fuerza muscular para ayudar a reducir el riesgo de caídas y continuar viviendo una vida sana y activa. Por eso desarrollamos el Desafío de Sentarse y Levantarse, basado en el test de 5 repeticiones [2], para ayudar a indicar la fuerza y el rendimiento físico de tus extremidades inferiores. La innovadora Sesión en Vivo digital está diseñada para usar la cámara de tu computadora para guiarte a través del Desafío y determinar tu edad muscular.",
    },

    cameraFallback: {
      camera: "¿No tienes cámara? ¡No hay problema!",
      encouraging: "Cronometra tu tiempo haciendo el Desafío de Sentarse y Levantarse y escribe tus resultados en el formulario para ver cuál es tu edad muscular.",
      getStartExplain: "¡Después de los 40 años, puedes perder hasta un 8% de masa muscular cada 10 años!",
      motivating: "Prepárate para el Desafío",
      completeTestRep: "Completa una repetición de prueba",
      explanatory: "Para completar el Desafío de Sentarse y Levantarse, necesitarás encontrar una silla que puedas usar para sentarte y levantarte.",
      stepByStep: "Para realizar una repetición de Sentarse y Levantarse, con los brazos cruzados sobre el pecho, necesitas sentarte en la silla y levantarte lo más rápido posible para completar las 5 repeticiones.",
      instructive: "Cuando estés listo, cronometra lo rápido que puedes completar las 5 repeticiones de Sentarse y Levantarse. Cuando termines, introduce tu tiempo total a continuación para obtener tu edad muscular.",
      inputTestTime: "Introduce tu tiempo de prueba (en segundos)",
      yourTime: "Tu tiempo de prueba",
      calculateMuscle: "¡Calcula mi edad muscular!",
      startChallenge: "Comienza el Desafío",
    },

    userPreparation: {
      chair: "Coloca una silla a 2 metros frente a la cámara",
      wait: "Por favor espera mientras se carga el modelo de IA",
      notVisibleKnees: "¡Asegúrate de que tus <bold>rodillas y hombros</bold> sean visibles!",
      nextStep: "Continúa al siguiente paso",
      description: "Mira el video instructivo del Desafío de Sentarse y Levantarse",
      startTheChairStandTest: "Comienza el Desafío de Sentarse y Levantarse",
    },

    SquatTest: {
      1: "¡Genial! ¡Quedan 4 repeticiones!",
      2: "",
      3: "¡Perfecto! ¡Casi estás a mitad de camino!",
      attach: "¡Quedan 2 repeticiones!",
      4: "¡Última!",
      default: "¡Completa las 5 repeticiones lo más rápido que puedas!",
    },

    testCompletionMessage: {
      congratulation: "¡Has completado el Desafío de Sentarse y Levantarse!",
      processing: "Ahora calculando tu edad muscular...",
    },

    results: {
      shareResults: "Comparte tus resultados",
      utterGood: "¡Buen trabajo! Parece que tus piernas son más fuertes que el promedio de tu grupo de edad. Sigue haciendo lo que estás haciendo",
      utterBad: "¡OH NO! Tus músculos necesitan ayuda. Es hora de concentrarse en aumentar tu masa muscular y fuerza.",
      utterOnPar: "¡Buen trabajo! Parece que tus piernas son tan fuertes como se espera para tu grupo de edad. Considera hablar con tu médico si estás interesado en aumentar o mantener tu fuerza muscular.",

      output: "Tu edad muscular está en el rango de: ",
      footNoteFaster75: "Tu resultado es más rápido que el 75% de tus compañeros entre el rango de edad de {{actualAge}}.",
      footNoteOnPar: "Tu resultado está dentro del 25%-75% de tus compañeros entre el rango de edad de {{actualAge}}.",
      footNoteSlower75: "Tu resultado es más lento que la norma para tu edad, y más lento que la velocidad media para el rango de edad de {{actualAge}}.",

      footNoteFaster80: "Tu resultado es más rápido que el 75% de tus compañeros de 80+ años.",
      footNoteOnPar80: "Tu resultado está dentro del 25%-75% de tus compañeros de 80+ años.",
      footNoteSlower80: "Tu resultado es más lento que la norma para tu edad, y más lento que la velocidad media para los 80 años.",

      percentileBelow25: "Tu resultado está por debajo del percentil 25 para la categoría de edad de 40-44.",
      percentileAbove25: "Tu resultado es igual o superior al percentil 25 para la categoría de edad de {{outputAge}}.",
      percentileBelow75: "Tu resultado es igual o inferior al percentil 75 para la categoría de edad de {{outputAge}}.",

      unsupportedGroup: "grupo no soportado",
      invalidLinkOrUnsupportedProcess: "Este enlace está roto o el proceso es incorrecto, ¡inténtalo de nuevo!",
      year: " años",
    },

    manualTest: {
      manualbutton: "¿No tienes cámara? ¡No hay problema! Puedes cronometrarte haciendo el Desafío de Sentarse y Levantarse y escribir tu tiempo en la calculadora manualmente.<bold> Participa en el desafío sin una cámara aquí</bold>",
      EnterDetails: "Introduce tus datos en la calculadora de edad muscular a continuación.",
      Step3: "Paso 3",
      age: "Edad",
      gender: "Género",
      timeInSeconds: "Tiempo en segundos*",
      submit: "ENVIAR",
      sitToStand: "Tiempo de Sentarse y Levantarse (por ejemplo, 7.5 segundos)",
      Title: "Recupera la fuerza, mantente activo",
      forYourOwnSafety: "Para tu propia seguridad",
      forYourOwnSafetyList: {
        1: "Ten cuidado de no esforzarte o lesionarte.",
        2: "Detén el desafío inmediatamente si tus rodillas comienzan a doler.",
        3: "No te inclines hacia atrás para ganar impulso o podrías caerte hacia atrás.",
        4: "Realiza la prueba cuando tengas un amigo o familiar cerca para apoyarte.",
        5: "No intentes la prueba si has tenido una caída o lesión reciente.",
      },
    },

    calculation: "La calculadora de edad muscular se basa en la referencia del test de 5 repeticiones de sentarse y levantarse, que puede ser un indicador de la fuerza y el rendimiento físico de los músculos en las extremidades inferiores.[2]",
    reference: "[1] Baier S, et al. JPEN. 2009; 33:71–82. [2] Dodds RM, et al. J Cachexia Sarcopenia Muscle. 2021;12(2):308–318.",
    reference2: "[2] test de 5 sillas según el documento",
    didYouKnow: "¿SABÍAS QUE?",
  },
};

export default esTranslation;
