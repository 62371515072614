//* Malay translation

const malayTranslation = {
  translation: {
    iosAlert:'Pengguna iOS dan Mac perlu pergi ke bahagian "Tetapan" penyemak imbas mereka dan ketik pilihan "Tambah ke Skrin Utama" untuk memasang perisian.',
    installDescription:"Pasang aplikasi ke peranti anda untuk mengaksesnya dengan mudah pada bila-bila masa. Tiada kedai aplikasi. Tiada muat turun. Tiada masalah.",

    homePage: {
      muscleAgeTitle: "Terokai usia otot anda",
      exercise:
        "Lakukan Cabaran Berdiri dari Kerusi selama 1 minit untuk melihat berapa usia otot anda sekarang!",
      atrophy:
        "Selepas umur 40 tahun, anda boleh kehilangan sehingga 8% jisim otot setiap dekad [1].",
      selectYourAge: "Pilih umur anda",
      selectGender: "Pilih jantina anda",
      male: "Lelaki",
      female: "Perempuan",
      muscleAge: "Lihat Usia Otot Anda",
      limitation:
        "Alat ini hanya sesuai untuk individu berumur lebih 40 tahun",
      muscleAgeAccessCamera: "Mengakses Kamera...",
      startYourMuscleAge: "Adalah penting untuk memantau dan mengekalkan kekuatan otot anda untuk mencegah jatuh dan terus menjalani kehidupan yang sihat dan aktif. Itulah sebabnya kami telah membangunkan Cabaran Stand Chair - berdasarkan ujian 5 kali Chair to Stand, untuk membantu menunjukkan kekuatan dan prestasi fizikal anggota badan bawah anda. Sesi Live digital yang inovatif direka untuk menggunakan kamera komputer anda untuk membantu anda melalui Cabaran dan menentukan usia otot anda.",
    },

    cameraFallback: {
      camera: "Tiada Kamera? Tiada Masalah!",
      encouraging: "Masa sendiri dan masukkan masa ujian anda di bawah apabila anda selesai untuk mendapatkan usia otot anda!",
      getStartExplain: "Selepas umur 40 tahun, anda boleh kehilangan sehingga 8% jisim otot setiap 10 tahun. Luangkan masa 3 minit dengan ujian Berdiri dari Kerusi kami untuk mengetahui berapa usia otot anda.",
      motivating: "Bersedia untuk Cabaran",
      completeTestRep: "Selesaikan Ulangan Ujian",
      explanatory: "Untuk melengkapkan Cabaran Berdiri dari Kerusi, anda perlu mencari kerusi yang boleh anda gunakan untuk duduk dan berdiri.",
      stepByStep: "Untuk melakukan ulang kaji Berdiri dari Kerusi, dengan tangan anda dilipat di dada anda, anda perlu duduk di kerusi dan berdiri secepat mungkin untuk melengkapkan 5 ulang kaji.",
      instructive: "Apabila anda sudah bersedia, masa berapa cepat anda boleh melengkapkan 5 ulang kaji Berdiri dari Kerusi. Apabila anda selesai, masukkan masa total anda di bawah untuk mendapatkan usia otot anda!",
      inputTestTime: "Masukkan masa ujian anda (Masa ujian anda dalam saat)",
      yourTime: "Masa ujian anda",
      calculateMuscle: "Kira Usia Otot Saya",
      startChallenge: "Mula Cabaran"
    },

    userPreparation: {
      chair: "Sila cari kerusi untuk digunakan untuk ujian dan letakkan kira-kira 1 meter di hadapan kamera anda.",
      wait: "Sila tunggu semasa model AI sedang dimuat",
      notVisibleKnees: " Pastikan lutut anda kelihatan.",
      nextStep: "Teruskan ke Langkah Seterusnya",
      description: "Tonton video arahan ujian berdiri dari kerusi", //! Double check...
      startTheChairStandTest: "Mulakan ujian berdiri dari kerusi",//! Double check...
    },

    SquatTest: {
      1: "Baik! 4 ulangan lagi!",
      2: "",
      3: "Hebat! Anda hampir separuh jalan!",
      attach:"2 ulangan lagi!",
      4: "Ulangan Terakhir!",
      default: "Selesaikan 5 ulangan berdiri dari kerusi untuk melihat usia otot anda!",
    },

    testCompletionMessage: {
      congratulation: "Anda telah melengkapkan Chair Stand Challenge!",
      processing: "Sekarang mengira umur otot anda…",
    },

    results: {
      shareResults: "Kongsikan Keputusan Anda",
      utterGood:
        "Bagus! Nampaknya usia otot anda lebih muda daripada umur anda",
      utterBad:
        "Oh sayang! Nampaknya umur otot anda lebih tua daripada umur anda. Sudah tiba masanya untuk memberi tumpuan kepada membina jisim dan kekuatan otot anda.",
      utterOnPar:
        "Bagus! Nampaknya umur otot anda setanding dengan umur anda",

      output: "Output kalkulator umur otot: ",
      footNoteFaster75:
        "Hasil anda lebih cepat daripada 75% rakan sebaya anda dalam julat umur {{actualAge}} tahun.",
      footNoteOnPar:
        "Hasil anda berada dalam lingkungan 25%-75% daripada rakan sebaya anda dalam julat umur {{actualAge}} tahun",
      footNoteSlower75:
        "Hasil anda lebih lambat daripada 75% rakan sebaya anda dalam julat umur {{actualAge}} tahun.",

      footNoteFaster80:
        "Hasil anda lebih cepat daripada 75% rakan sebaya anda berumur 80 tahun ke atas..",
      footNoteOnPar80: "Hasil anda berada dalam lingkungan 25%-75% daripada rakan sebaya anda berumur 80 tahun ke atas.",
      footNoteSlower80:
        "Hasil anda lebih lambat daripada 75% rakan sebaya anda berumur 80 tahun ke atas.",

      percentileBelow25:
        "Hasil anda adalah di bawah persentil ke-25 untuk kategori umur 40-44 tahun.",
      percentileAbove25:
        "Hasil anda adalah sama atau di atas persentil ke-25 untuk kategori umur {{outputAge}} tahun.",
      percentileBelow75:
        "Hasil anda adalah sama atau di bawah persentil ke-75 untuk kategori umur {{outputAge}} tahun.",

      unsupportedGroup: "kumpulan yang tidak disokong",
      invalidLinkOrUnsupportedProcess:
      "Pautan ini rosak atau prosesnya tidak betul, sila cuba lagi!",
      year: " tahun",
    },
    
    manualTest: {
      manualbutton: "Tiada kamera? Tiada masalah! Anda boleh mengukur masa anda sendiri semasa melakukan Cabaran Stand Chair dan memasukkan masa anda ke dalam kalkulator secara manual. <bold> Sertai cabaran tanpa kamera di sini</bold>",
      EnterDetails: "Masukkan butiran anda ke dalam pengira umur otot di bawah.",
      Step3: "Langkah 3",
      age: "Umur",
      gender: "Jantina",
      timeInSeconds: "Masa (saat)*",
      submit: "HANTAR",
      sitToStand: "Masa Duduk-Berdiri (contohnya 7.5 saat)",
      Title: "Laksanakan ujian kami untuk mengetahui usia otot anda sekarang",
      forYourOwnSafety: "Demi keselamatan anda sendiri",
      forYourOwnSafetyList: {
      1: "Berhati-hati agar tidak menegangkan atau mencederakan diri anda.",
      2: "Hentikan cabaran dengan segera jika lutut anda mula sakit.",
      3: "Jangan bersandar ke belakang untuk mendapatkan momentum atau anda mungkin terjatuh ke belakang.",
      4: "Lakukan ujian ketika anda mempunyai rakan atau ahli keluarga di sekitar untuk menyokong anda.",
      5: "Jangan cuba ujian jika anda baru sahaja jatuh atau cedera.",
      },
      },

    calculation:
      "Kalkulator usia otot berdasarkan rujukan ujian berdiri dari kerusi 5 kali yang boleh menjadi petunjuk kekuatan dan prestasi fizikal otot di bahagian bawah kaki.",
    reference:
      "[1] Baier S et al.JPEN 2009; 33:71-82. Berdasarkan pengukuran jisim badan tanpa lemak (LBM)",
    didYouKnow: "Adakah anda tahu?",
  },
};

export default malayTranslation;
