import { createSlice } from "@reduxjs/toolkit";
import initialState from "../initialState";

const slice = createSlice({
  name: "userConfig",
  initialState: initialState.userConfig,
  reducers: {
    genderSetter: (userConfig, action) => {
      userConfig.gender = action.payload;
    },
    ageSetter: (userConfig, action) => {
      userConfig.age = action.payload;
    },
    timeSetter: (userConfig, action) => {
      userConfig.time = action.payload;
    },
    poseLandmarkSetter: (userConfig, action) => {
      userConfig.poseLandmark = action.payload;
    },
    cameraAccessSetter: (userConfig, action) => {
      userConfig.cameraAccess = action.payload;
    },
    selectedLanguageSetter: (userConfig, action) => {
      userConfig.language = action.payload;
    },
    paramLanguageSetter: (userConfig, action) => {
      userConfig.paramLanguage = action.payload;
    },
    isFullScreenSetter: (userConfig, action) => {
      userConfig.isFullScreen = action.payload;
    },
    isManualTestSetter: (userConfig, action) => {
      userConfig.isManualTest = action.payload;
    },
    personalInformationSetter: (userConfig, action) => {
      userConfig.personalInfoForm = action.payload;
    },
  },
});

const {
  genderSetter,
  ageSetter,
  timeSetter,
  poseLandmarkSetter,
  cameraAccessSetter,
  selectedLanguageSetter,
  paramLanguageSetter,
  isFullScreenSetter,
  isManualTestSetter,
  personalInformationSetter,
} = slice.actions;

export default slice.reducer;

// Actions
export const setGender = (gender) => (dispatch) =>
  dispatch(genderSetter(gender));

export const setAge = (age) => (dispatch) => dispatch(ageSetter(age));

export const setTime = (time) => (dispatch) => dispatch(timeSetter(time));

export const setPoseLandmark = (poseLandmark) => (dispatch) =>
  dispatch(poseLandmarkSetter(poseLandmark));

export const setCameraAccess = (cameraAccess) => (dispatch) =>
  dispatch(cameraAccessSetter(cameraAccess));

export const setSelectedLanguage = (language) => (dispatch) =>
  dispatch(selectedLanguageSetter(language));

export const setParamLanguage = (paramLanguage) => (dispatch) =>
  dispatch(paramLanguageSetter(paramLanguage));

export const setFullScreen = (isFullScreen) => (dispatch) =>
  dispatch(isFullScreenSetter(isFullScreen));

export const setManualTest = (isManualTest) => (dispatch) =>
  dispatch(isManualTestSetter(isManualTest));

export const setPersonalInformation = (personalInformation) => (dispatch) =>
  dispatch(personalInformationSetter(personalInformation));
