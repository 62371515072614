import { USER_INFORMATION_API } from "../utils/constants/externalUrls";
import apiClient from "./httpService";
import { password, userName } from './../utils/constants/constants';

export const submitForm = async (formData) => {
  const credentials = btoa(`${userName}:${password}`);

  const config = {
    headers: {
      Authorization: `Basic ${credentials}`
    }
  };
  const { data } = await apiClient.post(USER_INFORMATION_API, {...formData}, config);
  return data;
};
