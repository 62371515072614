const ageGroups = [
  { threshold: 6.0, ageGroup: "20" },
  { threshold: 6.8, ageGroup: "30" },
  { threshold: 7.6, ageGroup: "40" },
  { threshold: 7.7, ageGroup: "50" },
  { threshold: 8.5, ageGroup: "60" },
  { threshold: 10.0, ageGroup: "70" },
];

const getAgeGroup = (actualAgeGroup) => {
  switch (actualAgeGroup) {
    case "20":
    case "20-24":
    case "25-29":
      return "20-29";

    case "30":
    case "30-34":
    case "35-39":
      return "30-39";

    case "40":
    case "40-44":
    case "45-49":
      return "40-49";

    case "50":
    case "50-54":
    case "55-59":
      return "50-59";

    case "60":
    case "60-64":
    case "65-69":
      return "60-69";

    case "70":
    case "70-74":
    case "75-79":
      return "70-79";

    case "80+":
    case "+80":
      return "+80";
    default:
      break;
  }
};

const getAge = (time, ageGroups) => {
  for (const { threshold, ageGroup } of ageGroups) {
    if (time <= Number(threshold)) return ageGroup;
  }
  return "+80";
};

const getResult = (time, actualAgeGroup, percentile25, percentile75) => {
  let utter, footnote, outputAge, percentileResult;
  outputAge = getAge(time, ageGroups);
  switch (true) {
    case time < percentile25:
      utter = "utterGoodTipTop";
      footnote = {
        message: "footNoteFaster",
        outputAgeRange: getAgeGroup(outputAge),
      };
      break;

    case time === percentile25:
      utter = "utterGood";
      footnote = {
        message: "footNoteOnPar",
        outputAgeRange: "",
      };
      break;

    case percentile25 < time && time <= percentile75:
      utter = "utterOnPar";
      footnote = {
        message: "footNoteOnPeriod",
        outputAgeRange: getAgeGroup(outputAge),
      };
      break;

    case time > percentile75:
      utter = "utterBad";

      footnote =
        actualAgeGroup === "80+"
          ? {
              message: "footNoteSlower80",
              outputAgeRange: getAgeGroup(outputAge),
            }
          : {
              message: "footNoteSlower",
              outputAgeRange: getAgeGroup(outputAge),
            };

      break;

    default:
      break;
  }

  return { utter, footnote, percentileResult, outputAge };
};

const calculateSingaporeResult = (time, actualAgeGroup) => {
  const ageGroups = {
    "20-29": [6.0, 7.6],
    "30-39": [6.1, 7.8],
    "40-49": [7.6, 8.5],
    "50-59": [7.7, 10.0],
    "60-69": [7.8, 10.9],
    "70-79": [9.3, 11.5],
    "+80": [10.8, 13.5],
  };

  const [percentile25, percentile75] =
    ageGroups[getAgeGroup(actualAgeGroup)] || [];

  if (!percentile25 || !percentile75) {
    console.log("unsupported");
    return null;
  }
  return getResult(Number(time), actualAgeGroup, percentile25, percentile75);
};

export default calculateSingaporeResult;
