/*! **********************************************************************************************
 * This is the Layout component for the application.
 * The component imports several other components including Home, SelectSex, RecordVideo, SelectAge, AssistPage, Camera, and LanguageBtn.
 * The component uses the React Router to create different routes for each component.
 *
 *
 * Abbott Copywrite 2023
 ************************************************************************************************/

import { Routes, Route, useLocation } from "react-router-dom";
import {
  HOME,
  AI_ENABLED_CAMERA,
  CAMERA_FALLBACK,
  FIVE_SQUAT_TEST,
  MUSCLE_AGE_RESULTS,
  Test_Guide,
  TEST_COMPLETION_MESSAGE,
  Manual_Test,
  INSTRUCTIONS_MODAL
} from "../utils/constants/internalUrls";

import Home from "../components/homePage/home";
import InitialSetup from "../components/AICameraEnabledMode/InitialSetup";
import OutLetBackGround from "../components/layout/background";
import TestGuide from "./../components/AICameraEnabledMode/TestGuide";
import SquatTest from "../components/AICameraEnabledMode/SquatTest";
import TestCompletionMessage from "./../components/AICameraEnabledMode/testCompletionMessage";
import MuscleAgeResults from "../components/AICameraEnabledMode/muscleAgeResults";
import CameraFallback from "../components/cameraFallbackMode/cameraFallback";
import ManualTest from "../components/manualTest/ManualTest";
import { useTranslation } from "react-i18next";
import InstructionsModal from "../components/homePage/clientInfo/instructionsModal";

//! Layout start 
const Layout = () => {

//! Hooks 
  const location = useLocation();
  const { i18n } = useTranslation();
const isPwa=window.location.pathname.includes("pwa")
//! Generate route paths with `pwa` prefix
const generateRoutePath = (path) => {
  return `${path}pwa`;
};
//! input components 
  return (
    <>
    <div className={i18n.language === 'vn' ? 'font-san' : 'font-brandon'}>
      <Routes location = {location}>
        <Route element = {<OutLetBackGround />}>
 {isPwa 
   ? <Route path={generateRoutePath(HOME)} element={<Home />} /> 
   : <Route path = {HOME} element = {<Home />} />
  }
          <Route path = {AI_ENABLED_CAMERA} element = {<InitialSetup />} />
          <Route path = {Test_Guide} element = {<TestGuide />} />
          <Route path = {FIVE_SQUAT_TEST} element = {<SquatTest />} />
          <Route path = {MUSCLE_AGE_RESULTS} element = {<MuscleAgeResults />} />
          <Route path = {TEST_COMPLETION_MESSAGE} element = {<TestCompletionMessage />} />
          <Route path = {Manual_Test} element = {<ManualTest />} />
          <Route path = {CAMERA_FALLBACK} element = {<CameraFallback />} />
          <Route path = {INSTRUCTIONS_MODAL} element = {<InstructionsModal />} />
        </Route>
      </Routes>
      </div>
    </>
  );
};

export default Layout;
