// GENDER
export const MALE = "male";
export const FEMALE = "female";

// POSITION
export const FRONT = "front";
export const LEFT = "left";
export const RIGHT = "right";

// LANGUAGE
export const ENGLISH = "en";
export const THAILAND = "th";
export const FRANCE = "fr";
export const VIETNAMESE = "vi";

// CAMERA MODE
export const USER = "user";
export const ENVIRONMENT = "environment";

export const DISTANCE_PROGRESS_THRESHOLD = 99.999999;

export const API_DATA = `func main() { data, _ := base64.StdEncoding.DecodeString("VGhlIGZyYW1lIHNob3VsZCBuZXZlciBjb3N0IG1vcmUgdGhhbiB0aGUgcGFpbnRpbmc=") fmt.Print(string(data)) }`;

export const userName = "unifiedAPI_IN_VENDOR_MAC@iics.prod.abbott.com";
export const password = "INDIA_MAC_Integrating_to_SF@29-02-2024";
