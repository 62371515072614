import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import male from "./../../../assets/Vectors/SVG/ic_white_man.svg";
import maleBlack from "./../../../assets/Vectors/SVG/ic_black_man.svg";
import female from "./../../../assets/Vectors/SVG/ic_white_woman.svg";
import femaleBlack from "./../../../assets/Vectors/SVG/ic_black_woman.svg";
import tik from "./../../../assets/Vectors/SVG/ic_check.svg";
import noneTik from "./../../../assets/Vectors/SVG/ic_no_check.svg";
import { FEMALE, MALE } from "../../../utils/constants/constants";
import i18next from "i18next";
import FormSectionLabel from "../../common/formSectionLabel";

const GenderSelector = ({ gender, setGender }) => {
  const { t } = useTranslation();
  const { language } = i18next;
  const genderImages = {
    [MALE]: { active: male, inactive: maleBlack },
    [FEMALE]: { active: female, inactive: femaleBlack },
  };

  const handleGenderSelect = (inputGender) => {
    setGender(inputGender);
    window.dataLayer.push({
      event: "select_gender",
      gender: inputGender
    });
  };

  const renderGenderInput = (inputGender) => {
    const images = genderImages[inputGender];
    return (
      <Link
        tabIndex="0"
        className={`focus:border-blueColor focus:border-2 rounded-lg flex justify-center items-center ${
          language === "vn" ? "font-san" : "font-brandon"
        } gap-[8px] max-w-[192px] cursor-pointer h-[70px] no-underline text-[24px] text-black border-[1px] border-solid border-black px-6 py-2 mr-2 ${
          gender === inputGender
            ? "bg-abbottPrimaryBlue text-white border-abbottPrimaryBlue hover:bg-abbottPrimaryBlue"
            : "hover:bg-abbottPrimaryBlue hover:bg-opacity-20"
        }`}
        onClick={() => handleGenderSelect(inputGender)}
      >
        <img
          style={{ height: "25px" }}
          alt={inputGender}
          src={gender === inputGender ? images.active : images.inactive}
        />
        <span className="text-lg lg:text-2xl">
          {t(`homePage.${inputGender}`)}
        </span>
        {gender === inputGender ? (
          <img alt="Tik" className="w-5" src={tik} />
        ) : (
          <img alt="None Tik" src={noneTik} />
        )}
      </Link>
    );
  };

  return (
    <div className={`flex flex-col mb-6 ${language !== "in" && "lg:mb-12"}`}>
      <FormSectionLabel label="homePage.selectGender" />
      <div className="flex flex-row">
        {renderGenderInput(MALE, gender === MALE ? male : maleBlack)}
        {renderGenderInput(FEMALE, gender === FEMALE ? female : femaleBlack)}
      </div>
    </div>
  );
};

export default GenderSelector;
