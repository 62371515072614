/*! **********************************************************************************************
 * This code defines a function that calculates the angles of body joints and determines the sitting or standing position based on those angles.
 *
 *
 *
 * Abbott copyright 2023
 ************************************************************************************************/
import calculateAngle_shoulder_Hip_Knee from "../calculateAngle_shoulder_Hip_Knee";
import calculateAngle_Ankle_Knee_Hip from "../calculateAngle_Ankle_Knee_Hip";

// This function calculates the y and z coordinates of a specific point in a ...
//... 3D space based on the provided world landmark points.
function calculateCoordinate(globalLandmarks, index) {
  const y = globalLandmarks[0][index].y;
  const z = globalLandmarks[0][index].z;
  return [y, z];
}

function handleFrontPosition(
  counter,
  sitting,
  setSitting,
  globalLandmarks
) {

  const {
    lHip_coordinate,
    lKnee_coordinate,
    lAnkle_coordinate,
    lShoulder_coordinate,
    rHip_coordinate,
    rKnee_coordinate,
    rAnkle_coordinate,
    rShoulder_coordinate,
  } = {
    // * LEFT---------coordinates
    lHip_coordinate: calculateCoordinate(globalLandmarks, 23),
    lKnee_coordinate: calculateCoordinate(globalLandmarks, 25),
    lAnkle_coordinate: calculateCoordinate(globalLandmarks, 27),
    lShoulder_coordinate: calculateCoordinate(globalLandmarks, 11),
    // * Right---------coordinates
    rHip_coordinate: calculateCoordinate(globalLandmarks, 24),
    rKnee_coordinate: calculateCoordinate(globalLandmarks, 26),
    rAnkle_coordinate: calculateCoordinate(globalLandmarks, 28),
    rShoulder_coordinate: calculateCoordinate(globalLandmarks, 12),
  };


  // * LEFT angle calculate---- shoulder_Hip_Knee
  const angle_Shoulder_L = calculateAngle_shoulder_Hip_Knee(
    lShoulder_coordinate,
    lHip_coordinate,
    lKnee_coordinate
  );
  // * Right angle calculate---- shoulder_Hip_Knee
  const angle_Shoulder_R = calculateAngle_shoulder_Hip_Knee(
    rShoulder_coordinate,
    rHip_coordinate,
    rKnee_coordinate
  );


  // * LEFT angle calculate---- Ankle_Knee_Hip
  const angle_Ankle_L = calculateAngle_Ankle_Knee_Hip(
    lHip_coordinate,
    lKnee_coordinate,
    lAnkle_coordinate
  );
  // * Right angle calculate----  Ankle_Knee_Hip
  const angle_Ankle_R = calculateAngle_Ankle_Knee_Hip(
    rHip_coordinate,
    rKnee_coordinate,
    rAnkle_coordinate
  );


  // console.log(angle_Shoulder_L, angle_Shoulder_R);
  // Sitting condition
  let newCounter = counter;
  if (
    angle_Shoulder_L <= 120 &&
    angle_Shoulder_R <= 120 &&
    angle_Ankle_L >= 50 &&
    angle_Ankle_R >= 50
  ) {
    setSitting(true);
  }
  // Standing condition
  if (
    sitting &&
    angle_Shoulder_L > 150 &&
    angle_Shoulder_R > 150 &&
    angle_Ankle_L < 30 &&
    angle_Ankle_R < 30
  ) {
    newCounter += 1;
    setSitting(false);
  }


  return { counter: newCounter };
}

export default handleFrontPosition;
