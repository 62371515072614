import FormSectionLabel from "../../../common/formSectionLabel";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const PersonalInformationForm = ({
  personalInfoForm: form,
  errors,
  onChange,
}) => {
  const { t } = useTranslation();

  const handleErrorMsg = (error, fieldName) => {
    if (!error) return "";

    return t(`errors.${error}`, {
      fieldName: fieldName,
    });
  };

  return (
    <>
      <FormSectionLabel label="homePage.personalInformation" />
      <form className="mb-6">
        <div className="flex gap-x-10 gap-y-2 max-md:flex-col mb-2">
          <TextField
            name="firstName"
            value={form?.firstName}
            onChange={onChange}
            className="w-full"
            label={t("homePage.firstName")}
            variant="standard"
            error={!!errors.firstName}
            helperText={handleErrorMsg(errors.firstName, "firstName")}
          />
          <TextField
            name="lastName"
            value={form?.lastName}
            onChange={onChange}
            className="w-full"
            label={t("homePage.lastName")}
            variant="standard"
            error={!!errors.lastName}
            helperText={handleErrorMsg(errors.lastName, "lastName")}
          />
        </div>
        <div className="flex gap-x-10 gap-y-2 max-md:flex-col">
          <TextField
            name="emailAddress"
            value={form?.emailAddress}
            onChange={onChange}
            className="w-full"
            label={t("homePage.emailAddress")}
            variant="standard"
            error={!!errors.emailAddress}
            helperText={handleErrorMsg(errors.emailAddress, "emailAddress")}
          />
          <TextField
            name="mobilePhone"
            value={form?.mobilePhone}
            onChange={onChange}
            className="w-full"
            label={t("homePage.mobilePhone")}
            variant="standard"
            error={!!errors.mobilePhone}
            helperText={handleErrorMsg(errors.mobilePhone, "mobilePhone")}
          />
        </div>
      </form>
    </>
  );
};

export default PersonalInformationForm;
