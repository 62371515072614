/***********************************************
 * top_down⬆⬇
 * Component: LanguageSelector
 * Description: Language management component
 *
 * Key Components:
 * - _imports
 * - _states
 * - set/Update language: _set , _Update
 * - _Menu
 * - _Button
 * - _UI
 *
 * Copyright:
 * (c) Abbott 2023
 **********************************************/

/* <--------------_imports-> Libraries--------------> */
import { useState, useEffect } from "react";
import i18n from "i18next";
import { Box, Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

/* <--------------_imports-> Flags icon--------------> */
import EnglishFlag from "./../../../assets/Vectors/SVG/Flags/ic_UK.svg";
import SpanishFlag from "./../../../assets/Vectors/SVG/Flags/ic_spanish.svg";
import ThailandFlag from "./../../../assets/Vectors/SVG/Flags/ic_thailand.svg";
import MalaysiaFlag from "./../../../assets/Vectors/SVG/Flags/ic_malaysia.svg";
import VietnamFlag from "./../../../assets/Vectors/SVG/Flags/ic_vietnam.svg";
import IndiaFlag from "./../../../assets/Vectors/SVG/Flags/ic_india.svg";
import AustraliaFlag from "./../../../assets/Vectors/SVG/Flags/ic_australia.svg";
import { useLocation, useParams } from "react-router-dom";

// ==============================
// start component
// ==============================
const LanguageSelector = ({
  selectedLanguage,
  setSelectedLanguage,
  paramLanguage,
  setParamLanguage,
  initialLanguageLoaded,
  setInitialLanguageLoaded,
}) => {
  const params = useParams();
  let location = useLocation();
  const menuOptions = [];

  /* <--------------languages helper objects--------------> */
  const supportedLanguages = {
    en: { label: "English", flag: EnglishFlag },
    es: { label: "Spanish", flag: SpanishFlag },
    th: { label: "Thailand", flag: ThailandFlag },
    my: { label: "Malaysia", flag: MalaysiaFlag },
    vn: { label: "Vietnam", flag: VietnamFlag },
    in: { label: "India", flag: IndiaFlag },
    au: { label: "Australia", flag: AustraliaFlag },
  };

  /* <--------------If params includes language--------------> */
  if (location.pathname === `/${params?.lan}` || location.pathname === "/")
    setParamLanguage(params?.lan);

  const languageParams =
    params?.lan ?? (selectedLanguage === "" ? "es" : selectedLanguage);

  /* <--------------_states--------------> */
  const [anchorEl, setAnchorEl] = useState(null);

  /* <--------------Update language helper function--------------> */
  const setLanguage = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
    setInitialLanguageLoaded(true);
  };

  const open = Boolean(anchorEl);

  /* <--------------Function to detect and _set the language--------------> */
  useEffect(() => {
    const detectLanguage = () => {
      try {
        if (initialLanguageLoaded) {
          setLanguage(selectedLanguage);
        } else if (languageParams === "my") {
          setLanguage("en");
        } else if (languageParams) {
          setLanguage(languageParams);
        }
      } catch (error) {
        console.error(
          "An error was occurred in select language component:",
          error
        );
      }
    };
    const event = new CustomEvent("languageChanged", {
      detail: { language: selectedLanguage },
    });
    window.dispatchEvent(event);
    detectLanguage();
  }, [languageParams, selectedLanguage, initialLanguageLoaded]);

  const anchorOnClick = ({ currentTarget }) => setAnchorEl(currentTarget);
  const anchorClose = () => setAnchorEl(null);

  /* <--------------_Update language--------------> */
  const updateLanguage = ({ currentTarget: input }) => {
    const language = input.getAttribute("value");
    setLanguage(language);
    anchorClose();
  };

  // If there is a language in the params and it is not English, add it to the menu options.
  if (
    paramLanguage === "en" ||
    paramLanguage === undefined ||
    paramLanguage === ""
  ) {
    menuOptions?.push([
      { languageCode: "en", label: "English", flag: EnglishFlag },
      { languageCode: "es", label: "Spanish", flag: SpanishFlag },
    ]);
  } else if (paramLanguage !== "en" && paramLanguage === "au") {
    menuOptions?.push([
      {
        languageCode: paramLanguage,
        label: supportedLanguages[paramLanguage]?.label,
        flag: supportedLanguages[paramLanguage]?.flag,
      },
    ]);
  } else if (paramLanguage !== "en") {
    menuOptions?.push([
      {
        languageCode: paramLanguage,
        label: supportedLanguages[paramLanguage]?.label,
        flag: supportedLanguages[paramLanguage]?.flag,
      },
      {
        languageCode: "en",
        label: supportedLanguages.en.label,
        flag: supportedLanguages.en.flag,
      },
    ]);
  }

  // ==============================
  // _UI
  // ==============================
  /* <--------------Render _Button--------------> */
  const renderButton = () => {
    return (
      <Button
        style={{ minWidth: 0, color: "inherit" }}
        id="basic-button"
        aria-controls={open && "basic-menu"}
        aria-haspopup="menu"
        aria-expanded={open ? "true" : undefined}
        onClick={anchorOnClick}
        startIcon={
          <img
            className="w-[20px] h-[20px] p-m-0"
            alt="flag"
            src={supportedLanguages[selectedLanguage]?.flag}
          />
        }
      >
        <span>{selectedLanguage.toUpperCase()}</span>
      </Button>
    );
  };

  /* <--------------Render _Menu--------------> */
  const renderMenu = () => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={anchorClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {Object.entries(menuOptions[0])?.map(([languageNum, item]) => (
          <MenuItem
            key={languageNum}
            value={item?.languageCode}
            className="center-x-y gap-[10px]"
            onClick={updateLanguage}
          >
            <img
              className="w-[20px] h-[20px]"
              alt={item?.label + " flag"}
              src={item?.flag}
            />
            <span className="font-brandon">{item?.label}</span>
          </MenuItem>
        ))}
      </Menu>
    );
  };

  /* <--------------Main UI--------------> */
  return (
    <Box className="relative w-fit h-fit">
      {renderButton()}
      {renderMenu()}
    </Box>
  );
};

export default LanguageSelector;

// ==============================
// top_down⬆⬇
// ==============================
